import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Slider from './Slider';
import { ToastContainer, toast } from 'react-toastify';
import ProgressBar from './ProgressBar';
import { Spinner } from 'react-bootstrap';

import io from 'socket.io-client';
const SOCKET_SERVER_URL = 'https://socket.cgc.ac.in/';

const Documents = () => {

    const excludedFields = [
        'alternate_phone', 'father_occupation', 'mother_occupation', 'twelfth_institute_name',
        'twelfth_board', 'twelfth_year_of_passing', 'twelfth_result_status', 'twelfth_marking_scheme',
        'twelfth_obtained_marks', 'twelfth_max_marks', 'twelfth_roll_no',
        'diploma_institute_name', 'diploma_board', 'diploma_year_of_passing',
        'diploma_result_status', 'diploma_marking_scheme', 'diploma_obtained_marks',
        'diploma_max_marks', 'step', 'completion_status', 'id', 'user_id',
        'lead_id', 'created', 'modified'
    ];

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        photo: '',
        sign: '',
        parentSign: '',
        xMarksheet: '',
        aadharCard: '',
    });

    const [isFileUploading, setIsFileUploading] = useState({
        photo: false,
        sign: false,
        parentSign: false,
        xMarksheet: false,
        aadharCard: false
    });

    const [studentId, setStudentId] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (formData.photo === "" || formData.sign === "" || formData.parentSign === "" || formData.xMarksheet === "" || formData.aadharCard === "") {
            toast.error("Please Upload Documents");
            return;
        }

        const response = await fetch(`/api/student_application?step=3&action=update_step`, {
            method: "POST",
            body: JSON.stringify({ something: "update", id: formData.id })
        })
        const data = await response.json();
        console.log(data);
        navigate('/form/declaration');

        // const totalFields = Object.keys(formData).filter(key => !excludedFields.includes(key)).length;
        // let filledFields = Object.entries(formData)
        //     .filter(([key, value]) => !excludedFields.includes(key) && value !== null && value !== '')
        //     .length;

        // const progress = (filledFields / totalFields) * 100;
        // const progress2 = Math.round(progress);
        // console.log("field counts", totalFields, filledFields, progress);

        // console.log(photo, sign, parentSign, xMarksheet, aadharCard);



        // const newFormData = new FormData()
        // newFormData.append('photo', photo);
        // newFormData.append('sign', sign);
        // newFormData.append('parentSign', parentSign);
        // newFormData.append('xMarksheet', xMarksheet);
        // newFormData.append('aadharCard', aadharCard);

        // console.log([...newFormData]);

        // // const response = await fetch(`/api/upload_docs?validate=validate_form&step=3&completion_status=${progress2}`, {
        // //     method: "POST",
        // //     body: newFormData
        // // });

        // const response = await fetch(`/api/student_application?step=3&completion_status=${progress2}&action=update_step`, {
        //     method: "POST",
        //     body: JSON.stringify({ something: "update" })
        // })
        // const data = await response.json();
        // if (data.suc) {
        //     toast.success(data.msg);
        //     // toast.success("documents uploaded successfully");
        // }
        // else {
        //     // toast.error("failed to upload documents")
        //     toast.error(data.msg);
        // }
        // navigate('/form/declaration');
    };

    const handleSaveData2 = () => {
        toast.success("Data saved");
        // handleSaveData(true);
    }

    useEffect(() => {
        if (!studentId) return;

        const socket = io(SOCKET_SERVER_URL);

        // Emit 'online' status when component mounts
        socket.emit('student_status', { status: 'online', user_id: studentId });

        // Emit 'offline' status when component unmounts
        return () => {
            socket.emit('student_status', { status: 'offline', user_id: studentId });
            socket.disconnect(); // Disconnect socket when the component unmounts
        };
    }, [studentId]);

    const sendValueToServer = (inputValue) => {
        const socket = io(SOCKET_SERVER_URL);
        const id = studentId; // student user_id, you can use it 
        console.log("dataaaaa", id, inputValue);
        socket.emit('set_value', { value: inputValue, user_id: id }); // Emit the 'set_value' event with the input data
    };

    const handleSaveData = async (btnCount) => {
        const totalFields = Object.keys(formData).filter(key => !excludedFields.includes(key)).length;
        let filledFields = Object.entries(formData)
            .filter(([key, value]) => !excludedFields.includes(key) && value !== null && value !== '')
            .length + 1;

        const progress = (filledFields / totalFields) * 100;
        const progress2 = Math.round(progress);
        console.log("field counts", totalFields, filledFields, progress);

        

        console.log(photo, sign, parentSign, xMarksheet, aadharCard);

        const newFormData = new FormData();
        if (btnCount === 1) newFormData.append('photo', photo);
        if (btnCount === 2) newFormData.append('sign', sign);
        if (btnCount === 3) newFormData.append('parentSign', parentSign);
        if (btnCount === 4) newFormData.append('xMarksheet', xMarksheet);
        if (btnCount === 5) newFormData.append('aadharCard', aadharCard);


        console.log([...newFormData]);

        const updatedUploadingState = { ...isFileUploading };
        const updatedFormData = { ...formData }

        if (photo && formData.photo === "" && btnCount === 1) {
            updatedUploadingState.photo = true
            updatedFormData.photo = "uploaded"
        };
        if (sign && formData.sign === "" && btnCount === 2) {
            updatedUploadingState.sign = true;
            updatedFormData.sign = "uploaded"
        }
        if (parentSign && formData.parentSign === "" && btnCount === 3) {
            updatedUploadingState.parentSign = true;
            updatedFormData.parentSign = "uploaded"
        }
        if (xMarksheet && formData.xMarksheet === "" && btnCount === 4) {
            updatedUploadingState.xMarksheet = true;
            updatedFormData.xMarksheet = "uploaded"
        }
        if (aadharCard && formData.aadharCard === "" && btnCount === 5) {
            updatedUploadingState.aadharCard = true;
            updatedFormData.aadharCard = "uploaded"
        }

        // Update the upload state
        setIsFileUploading(updatedUploadingState);
        setFormData(updatedFormData)

        const response = await fetch(`/api/upload_docs?completion_status=${progress2}`, {
            method: "POST",
            body: newFormData
        });
        const data = await response.json();


        if (data.suc) {
            sendValueToServer(progress2);
            toast.success(data.msg);
            
            setIsFileUploading({
                photo: false,
                sign: false,
                parentSign: false,
                xMarksheet: false,
                aadharCard: false
            });

            if (btnCount === 1) setPhoto(null);
            if (btnCount === 2) setSign(null);
            if (btnCount === 3) setParentSign(null);
            if (btnCount === 4) setXMarksheet(null);
            if (btnCount === 5) setAadharCard(null);

        }
        else {
            setIsFileUploading({
                photo: false,
                sign: false,
                parentSign: false,
                xMarksheet: false,
                aadharCard: false
            });
            toast.error(data.msg);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = "/logout";
    };

    const [photo, setPhoto] = useState(null);
    const [sign, setSign] = useState(null);
    const [parentSign, setParentSign] = useState(null);
    const [xMarksheet, setXMarksheet] = useState(null);
    const [aadharCard, setAadharCard] = useState(null);

    const handleChange1 = (e) => {
        setPhoto(e.target.files[0]);
    }

    const handleChange2 = (e) => {
        setSign(e.target.files[0]);
    }

    const handleChange3 = (e) => {
        setParentSign(e.target.files[0]);
    }

    const handleChange4 = (e) => {
        setXMarksheet(e.target.files[0]);
    }

    const handleChange5 = (e) => {
        setAadharCard(e.target.files[0]);
    }

    useEffect(() => {
        getStudentData();
    }, [])

    const getStudentData = async () => {
        const response = await fetch("/api/fetch_student_application");
        const data1 = await response.json();
        console.log("student data", data1);
        if (data1) {
            setFormData(data1.data[0]);
            setStudentId(data1.data.lead_id);
        }
    }

    const handleBack = () => {
        navigate('/form/educational_details');
    }

    return (
        <div>
            <ToastContainer />
            <header>
                <div className="container5 fixed-header py-2 px-3" style={{ boxShadow: "rgb(194 197 201) 0px 4px 20px" }}>
                    <nav>
                        <div className="navbar-header d-flex justify-content-between align-items-center">
                            <Link to='/form'>
                                <img src="https://cdn.npfs.co/uploads/college/image/5f2a94bfe915e036895412_Webp.net_resizeimage.png" style={{ width: "10rem" }} alt="Logo" />
                            </Link>
                            <ul className='m-0 p-0' style={{ listStyle: "none" }}>
                                <li>
                                    <button type='button' className="btn btn-primary" onClick={handleLogout} title='logout'>
                                        <i className="fa-solid fa-arrow-right-from-bracket text-start"></i> Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>

            <div className="container mb-4" style={{ marginTop: "6.5rem" }}>
                <div>
                    <Slider activeIndex={2} step={formData.step} />
                </div>

                <div>
                    <ProgressBar formData={formData} />
                </div>
            </div>

            <div className="container my-5 mt-0">
                <div className="panel">
                    <div className="panel-body">
                        <div className="row">
                            <form onSubmit={handleSubmit}>
                                <div className="row">

                                    <div className="col-md-6 mb-md-4 mb-2">
                                        <div className="form-group position-relative">
                                            <label className="form-label" htmlFor="photo">Upload Passport Size Photo <span className='star'>*</span></label>
                                            <input type="file" required disabled={formData.photo !== '' || photo === true} onChange={handleChange1} id='photo' name='photo' className='form-input' />

                                            {formData.photo !== '' && (isFileUploading.photo === true ? <label className="form-label faded mt-1">
                                                file uploading... <Spinner animation="border" variant="success" style={{ width: "18px", height: "18px" }} />
                                            </label> : <label className="form-label faded mt-1">file uploaded <i class="fa-solid fa-circle-check" style={{ color: "#00a876" }}></i> </label>)}

                                            {formData.photo === '' && <label className="form-label faded mt-1">Max Upload Limit 2 MB (only jpeg, jpg and png allowed)</label>}
                                            <button type='button' disabled={formData.photo !== '' || photo === true} onClick={() => { handleSaveData(1) }} className='btn btn-success me-2' style={{ borderRadius: "unset", position: "absolute", bottom: "34px", right: "0", padding: "2px 12px" }}>SAVE</button>
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-md-4 mb-2">
                                        <div className="form-group position-relative">
                                            <label className="form-label" htmlFor="sign">Upload Student Signature <span className='star'>*</span></label>
                                            <input type="file" required disabled={formData.sign !== ''} onChange={handleChange2} id='sign' name='sign' className='form-input' />
                                            {formData.sign !== '' && (isFileUploading.sign === true ? <label className="form-label faded mt-1">
                                                file uploading... <Spinner animation="border" variant="success" style={{ width: "18px", height: "18px" }} />
                                            </label> : <label className="form-label faded mt-1">file uploaded <i class="fa-solid fa-circle-check" style={{ color: "#00a876" }}></i> </label>)}
                                            {formData.sign === '' && <label className="form-label faded mt-1">Max Upload Limit 2 MB (only jpeg, jpg and png allowed)</label>}
                                            <button type='button' disabled={formData.sign !== ''} onClick={() => { handleSaveData(2) }} className='btn btn-success me-2' style={{ borderRadius: "unset", position: "absolute", bottom: "34px", right: "0", padding: "2px 12px" }}>SAVE</button>
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-md-4 mb-2">
                                        <div className="form-group position-relative">
                                            <label className="form-label" htmlFor="parent_sign">Upload Parent Name & Signature <span className='star'>*</span></label>
                                            <input type="file" required disabled={formData.parentSign !== ''} onChange={handleChange3} id='parent_sign' name='parent_sign' className='form-input' />
                                            {formData.parentSign !== '' && (isFileUploading.parentSign === true ? <label className="form-label faded mt-1">
                                                file uploading... <Spinner animation="border" variant="success" style={{ width: "18px", height: "18px" }} />
                                            </label> : <label className="form-label faded mt-1">file uploaded <i class="fa-solid fa-circle-check" style={{ color: "#00a876" }}></i> </label>)}
                                            {formData.parentSign === '' && <label className="form-label faded mt-1">Max Upload Limit 2 MB (only jpeg, jpg and png allowed)</label>}
                                            <button type='button' disabled={formData.parentSign !== ''} onClick={() => { handleSaveData(3) }} className='btn btn-success me-2' style={{ borderRadius: "unset", position: "absolute", bottom: "34px", right: "0", padding: "2px 12px" }}>SAVE</button>
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-md-4 mb-2">
                                        <div className="form-group position-relative">
                                            <label className="form-label" htmlFor="x_marksheet">Upload 10th Marksheet <span className='star'>*</span></label>
                                            <input type="file" required disabled={formData.xMarksheet !== ''} onChange={handleChange4} id='10th_marksheet' name='10th_marksheet' className='form-input' />
                                            {formData.xMarksheet !== '' && (isFileUploading.xMarksheet === true ? <label className="form-label faded mt-1">
                                                file uploading... <Spinner animation="border" variant="success" style={{ width: "18px", height: "18px" }} />
                                            </label> : <label className="form-label faded mt-1">file uploaded <i class="fa-solid fa-circle-check" style={{ color: "#00a876" }}></i> </label>)}
                                            {formData.xMarksheet === '' && <label className="form-label faded mt-1">Max Upload Limit 2 MB (only jpeg, jpg and png allowed)</label>}
                                            <button type='button' disabled={formData.xMarksheet !== ''} onClick={() => { handleSaveData(4) }} className='btn btn-success me-2' style={{ borderRadius: "unset", position: "absolute", bottom: "34px", right: "0", padding: "2px 12px" }}>SAVE</button>
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-md-4 mb-2">
                                        <div className="form-group position-relative">
                                            <label className="form-label" htmlFor="aadhar_card">Upload Aadhaar Card <span className='star'>*</span></label>
                                            <input type="file" required disabled={formData.aadharCard !== ''} onChange={handleChange5} id='aadhar_card' name='aadhar_card' className='form-input' />
                                            {formData.aadharCard !== '' && (isFileUploading.aadharCard === true ? <label className="form-label faded mt-1">
                                                file uploading... <Spinner animation="border" variant="success" style={{ width: "18px", height: "18px" }} />
                                            </label> : <label className="form-label faded mt-1">file uploaded <i class="fa-solid fa-circle-check" style={{ color: "#00a876" }}></i> </label>)}
                                            {formData.aadharCard === '' && <label className="form-label faded mt-1">Max Upload Limit 2 MB (only jpeg, jpg and png allowed)</label>}
                                            <button type='button' disabled={formData.aadharCard !== ''} onClick={() => { handleSaveData(5) }} className='btn btn-success me-2' style={{ borderRadius: "unset", position: "absolute", bottom: "34px", right: "0", padding: "2px 12px" }}>SAVE</button>
                                        </div>
                                    </div>

                                </div>

                                <div className='d-none d-sm-block'>
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <button type='button' onClick={handleBack} className='btn btn-secondary me-2' style={{ borderRadius: "unset" }}>BACK</button>
                                            <button type='button' onClick={handleSaveData2} className='btn btn-secondary' style={{ borderRadius: "unset" }}>SAVE & EXIT</button>
                                        </div>
                                        <div className="col-6 text-end">

                                            <button type='submit' className='btn btn-primary' style={{ borderRadius: "unset" }}>NEXT</button>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-block d-sm-none'>
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-3">
                                            <button type='button' onClick={handleBack} className='btn btn-success' style={{ borderRadius: "50%" }}><i class="fa-solid fa-chevron-left"></i></button>
                                        </div>
                                        <div className="col-6 text-center">Step 3 of 5</div>
                                        <div className="col-3 text-end">
                                            <button type='submit' className='btn btn-success' style={{ borderRadius: "50%" }}><i class="fa-solid fa-chevron-right"></i></button>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <button type='button' onClick={handleSaveData2} className='btn btn-outline-secondary' style={{ borderRadius: "unset", width: "100%" }}>SAVE & EXIT</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <footer>
                <div className="footer text-end p-3" style={{ backgroundColor: "#E5E5E5" }}>
                    <img src="https://cresttechnosoft.com/wp-content/uploads/2024/08/logo-3.svg" style={{ width: "8rem" }} alt="Footer Logo" />
                </div>
            </footer>
        </div>
    );
};

export default Documents;
