import { useState, useEffect } from "react"
import React from 'react'
import AddFollowupModal from "./AddFollowupModal"
import AddNoteModal from "./AddNoteModal"

const Followups = ({ id }) => {
    const [data, setData] = useState([])

    const handleAddFollowUp = () => {
        const modal = new window.bootstrap.Modal(document.getElementById('exampleModal3'));
        modal.show();
    }

    const handleAddNote = () => {
        const modal = new window.bootstrap.Modal(document.getElementById('exampleModal4'));
        modal.show();
    }

    useEffect(() => {
        getFollowup();
    }, [])

    const getFollowup = async () => {
        const response = await fetch(`/api/lead_follow_ups/${id}`)
        const data1 = await response.json();
        console.log(data1);
        if (data1.suc) {
            setData(data1.data);
        }
        else{
            setData([]);
        }
    }

    return (
        <div>
            <div className="values px-4 py-3">

                <div className="d-flex justify-content-between">
                    <div className="">
                        <h4>Follow Up & Notes</h4>
                    </div>

                    <div>
                        <button type="button" onClick={handleAddFollowUp} class="btn btn-outline-primary px-2 py-1">Add Follow Up</button>
                        {/* <button type="button" onClick={handleAddNote} class="btn btn-outline-primary px-2 py-1 ms-3">Add Note</button> */}
                    </div>
                </div>
                <hr className="mt-2" />

                <div className="created_atlines d-flex flex-column">
                    {data.map((element, index) => (
                        <div key={index} className='d-flex mb-5'>
                            <div className="col-2" style={{ width: "90px" }}>
                                <div className="created_at">{element.created_at}</div>

                            </div>
                            <div className="px-4">
                                <div className="icon">
                                    <div className='per-icon highlight' style={{ borderColor: "#007bff" }}>{element.type === "note" ? <i class="fa-solid fa-envelope"></i> : <i class="fa-solid fa-phone"></i>}</div>
                                </div>
                            </div>
                            <div className="nothing" style={{ width: "100%" }}>
                                {element.type === "followup" && (
                                    <div className="msg">
                                        <div className="inner-msg">
                                            <span style={{ fontWeight: "500" }}>Follow Up</span> - <span className=''>with {element.follow_up}</span>
                                            <br />
                                            <span style={{ fontWeight: "500" }}>Assigned To</span>: <span className=''>{element.assigned_to}</span> | <span style={{ fontWeight: "500" }}>due_date</span>: <span className=''>{element.due_date}</span>
                                            <br />
                                            <span style={{ fontWeight: "500" }}>Created By</span>: <span className=''>{element.created_by}</span> | <span style={{ fontWeight: "500" }}>Status</span>: <span style={{ color: `${element.status === "complete" ? "green" : "red"}` }}>{element.status}</span>
                                        </div>
                                    </div>
                                )}

                                {element.type === "note" && (
                                    <div className="msg">
                                        <div className="inner-msg">
                                            <span style={{ fontWeight: "500" }}>Note</span> - <span className=''>{element.note}</span>
                                            <br />
                                            <span style={{ fontWeight: "500" }}>Added By</span>: <span className=''>{element.added_by}</span>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    ))}
                </div>

            </div>





            <div className="modal fade" id="exampleModal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Add Follow Up</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <AddFollowupModal id={id} />

                    </div>
                </div>
            </div>

            <div className="modal fade" id="exampleModal4" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Add Note</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <AddNoteModal />

                    </div>
                </div>
            </div>

        </div>
    )
}

export default Followups
