import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Navbar from './Navbar'; // Assuming you still need to include the Navbar
import { toast, ToastContainer } from 'react-toastify';
import markIcon from '../images/mark.svg';
import bellIcon from '../images/bell.svg';
import LeadDetails from './LeadDetails';
import Timeline from './Timeline';
import Followups from './Followups';
import CommunicationLogs from './CommunicationLogs';
import DocumentLocker from './DocumentLocker';
import { Link } from 'react-router-dom';

import io from 'socket.io-client';
import Navbar2 from './Navbar2';
const SOCKET_SERVER_URL = 'https://socket.cgc.ac.in/';

const StudentDetails = () => {
    const { id } = useParams();
    const encodedData = window.atob(id);

    const [formData, setFormData] = useState({
        name: 'user',
        email: '',
        role: 'select',
        password: '',
    });

    const [applicationData, setApplicationData] = useState({ completion_status: "Untouched" })

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const link = document.createElement('link');
        const link2 = document.createElement('link');
        link.href = 'https://unpkg.com/@webpixels/css@1.1.5/dist/index.css';
        link.rel = 'stylesheet';

        link2.href = 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css';
        link2.rel = 'stylesheet';

        document.head.appendChild(link);
        document.head.appendChild(link2);

        return () => {
            document.head.removeChild(link);
            document.head.removeChild(link2);
        };
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(`/api/lead_summery/${encodedData}`);
            const data = await response.json();
            console.log(data);
            if (data.suc) {
                setFormData(data.lead);
                if (data.application != null) {
                    setApplicationData(data.application);
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error("something went wrong");
        }
    };

    const [currentActive, setCurrentActive] = useState("1");

    const handleChange1 = () => {
        setCurrentActive("1");
    }

    const handleChange2 = () => {
        setCurrentActive("2");
    }

    const handleChange3 = () => {
        setCurrentActive("3");
    }

    const handleChange4 = () => {
        setCurrentActive("4");
    }

    const handleChange5 = () => {
        setCurrentActive("5");
    }

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = "logout";
    }

    const [completeStatus, setCompleteStatus] = useState("null");

    useEffect(() => {
        const socket = io(SOCKET_SERVER_URL);

        const studentId = encodedData;

        socket.on('get_value', ({ value, user_id }) => {
            console.log('Received from server (get_value):', value, user_id, studentId);
            if (user_id === studentId) {
                setCompleteStatus(value); // Update the value in state for this student
            }
        });

        socket.on('get_student_status', ({ status, user_id }) => {
            console.log('Received from server (get_value):', status, user_id, studentId);
            if (user_id === studentId) {
                console.log(`Student ${user_id} is ${status}`);
                // setStudentStatus(status); // Set the student's status in your state (online/offline)
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    return (
        <div>
            <ToastContainer />
            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />
                <div className="d-flex flex-grow-1 overflow-y-lg-auto">
                    <main className="py-0 pt-0 bg-surface-secondary w-100" style={{ height: "100vh", overflow: "auto" }}>
                        <Navbar2 />
                        <div className="card-header mb-5">
                            <div>
                                <h3 className="mb-2">Lead Details</h3>
                            </div>

                            <div className="row mb-3">
                                <div className="col-lg-4 col-sm-8 mb-lg-0 mb-4">
                                    <div className="col-item col-item1">
                                        <div className="d-flex mb-2">
                                            <div className="me-3">
                                                <span className="square">
                                                    <i className="fa-solid fa-g"></i>
                                                </span>
                                            </div>
                                            <div className="">
                                                <div className="student-info">
                                                    <h4>{formData.name || "NA"}</h4>
                                                    <div>

                                                        <b>Lead stage: </b><span className='highlight'>{formData.lead_stage || "NA"}</span>
                                                    </div>
                                                    <div>
                                                        <b>Application stage: </b><span className='highlight'>{completeStatus === "null" ? applicationData.completion_status : completeStatus}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <p><span className='highlight'>Email : </span>{formData.email || "NA"}</p>
                                        <p><span className='highlight'>Mobile : </span>{formData.phone || "NA"}</p>
                                        <p><span className='highlight'>Added on : </span>{formData.created_at || "NA"}</p>
                                        <p><span className='highlight'>Last Active : </span>{formData.last_active || "NA"}</p>
                                    </div>
                                </div>

                                <div className="col-lg-2 col-sm-4 mb-lg-0 mb-4">
                                    <div className="row">
                                        <div className="col-12 p-lg-0">
                                            <div className="col-item col-item2 text-center">
                                                <h2>{formData.lead_score || "0"}</h2>
                                                <h5 className='highlight'>Lead Score <i className="fa-solid fa-circle-info"></i></h5>
                                            </div>
                                        </div>
                                        <div className="col-12 p-lg-0">
                                            <div className="col-item text-center">
                                                <div className="per-icon">
                                                    <i className="bi bi-percent"></i>
                                                </div>
                                                <h5 className='highlight'>Generate Lead Strength<i className="fa-solid fa-circle-info"></i></h5>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-6 ">
                                    <div className="col-item col-item3">

                                        <div className="row ">
                                            <div className="col-xl-4 col-lg-6 col-sm-4 col-6 mb-3 h-100">
                                                <h5 className="highlight">Communication Status</h5>
                                                <p>Email sent - {formData.email_sent || "0"}</p>
                                                <p>Sms sent - {formData.sms_sent || "0"}</p>
                                                <p>Whatsapp sent - {formData.whatsapp_sent || "0"}</p>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-sm-4 col-6 mb-3 h-100">
                                                <h5 className="highlight">Telephony Status</h5>
                                                <p>Inbound Call : {formData.inbound_call || "0"}</p>
                                                <p>Outbound Call : {formData.outbound_call || "0"}</p>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-sm-4 col-6 mb-3 h-100">
                                                <h5 className="highlight">Assigned Owner</h5>
                                                <p>{formData.assign_to || "NA"}</p>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-sm-4 col-6 mb-3 h-100">
                                                <h5 className="highlight">Upcoming Followup</h5>
                                                <p>{formData.upcoming_followup || "NA"}</p>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-sm-4 col-6 mb-3 h-100">
                                                <h5 className="highlight">Lead Source</h5>
                                                <p>{formData.utm_source || "NA"}</p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">

                                    <div className="bx-pager bx-default-pager">

                                        <div className={`bx-pager-item active`}>
                                            <span className="bx-pager-link " data-slide-index="0" >Unverified</span>
                                            <div className="arrow"></div>
                                        </div>

                                        <div className={`bx-pager-item ${applicationData.completion_status !== "Untouched" ? "active" : ""}`}>
                                            <span className="bx-pager-link" data-slide-index="1" >Verified</span>
                                            <div className="arrow"></div>
                                        </div>

                                        <div className={`bx-pager-item ${(applicationData.completion_status !== "12%" && applicationData.completion_status !== "Untouched") || (completeStatus !== "12%" && completeStatus !== "null") ? "active" : ""}`}>
                                            <span className="bx-pager-link" data-slide-index="2" >Application Started</span>
                                            <div className="arrow"></div>
                                        </div>

                                        <div className={`bx-pager-item ${applicationData.completion_status === "100%" || completeStatus === "100%" ? "active" : ""}`}>
                                            <span className="bx-pager-link" data-slide-index="2" >Application Submitted</span>
                                            <div className="arrow"></div>
                                        </div>

                                        <div className={`bx-pager-item`}>
                                            <span className="bx-pager-link" data-slide-index="2" >Payment Approved</span>
                                            <div className="arrow"></div>
                                        </div>

                                        <div className="bx-pager-item">
                                            <span className="bx-pager-link" data-slide-index="3" >Enrolment</span>
                                            <div className="arrow"></div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="d-flex flex-row mt-3">
                                <div className="col-3 main-option">
                                    <div className={`options p-3 ${currentActive === "1" ? "active4" : ""}`}>
                                        <button onClick={handleChange1} className='p-0 text-start w-100'> <h4><i class="fa-solid fa-user me-2"></i> Lead Details </h4></button>
                                    </div>
                                    <div className={`options p-3 ${currentActive === "2" ? "active4" : ""}`}>
                                        <button onClick={handleChange2} className='p-0 text-start w-100'> <h4><i class="fa-regular fa-clock me-2"></i> Timeline</h4> </button>
                                    </div>
                                    <div className={`options p-3 ${currentActive === "3" ? "active4" : ""}`}>
                                        <button onClick={handleChange3} className='p-0 text-start w-100'> <h4><i class="fa-regular fa-pen-to-square me-2"></i> Follow up & Notes</h4> </button>
                                    </div>
                                    <div className={`options p-3 ${currentActive === "4" ? "active4" : ""}`}>
                                        <button onClick={handleChange4} className='p-0 text-start w-100'> <h4><i class="fa-regular fa-envelope me-2"></i> Communication Logs</h4> </button>
                                    </div>
                                    <div className={`options p-3 ${currentActive === "5" ? "active4" : ""}`}>
                                        <button onClick={handleChange5} className='p-0 text-start w-100'> <h4><i class="fa-regular fa-folder me-2"></i> Document Locker</h4> </button>
                                    </div>
                                    {/* <div className="options p-3">
                                        <button className='p-0'> <h4><i class="fa-solid fa-ticket me-2"></i> Tickets</h4> </button>
                                    </div>
                                    <div className="options p-3">
                                        <button className='p-0'> <h4><i class="fa-solid fa-phone-volume me-2"></i> Call Logs</h4> </button>
                                    </div> */}
                                </div>
                                <div className="col-9">
                                    {currentActive === "1" && <LeadDetails formData={formData} applicationData={applicationData} id={encodedData} />}
                                    {currentActive === "2" && <Timeline id={encodedData} />}
                                    {currentActive === "3" && <Followups id={encodedData} />}
                                    {currentActive === "4" && <CommunicationLogs id={encodedData} />}
                                    {currentActive === "5" && <DocumentLocker id={encodedData} />}
                                </div>
                            </div>

                            {/* <div className="row align-items-center justify-content-between text-center">
                                <div className="col-md-9 mb-0 mb-md-0 text-start">
                                    <div>
                                        <h4>Add a New User</h4>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
};




export default StudentDetails
