import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import markIcon from '../images/mark.svg';
import bellIcon from '../images/bell.svg';

const Navbar2 = () => {
    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = "logout";
    }


    useEffect(() => {
        verifyUser();
    }, [])

    const [fullname, setFullname] = useState("");

    const verifyUser = async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await fetch(`/auth/get_user_data`, {
                headers: {
                    "Authorization": token
                }
            });
            const data = await response.json();
            if (data.suc) {
                console.log("Authentication completed");
                setFullname(data.auth.full_name);
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="card-header mb-1" style={{ position: "sticky", top: "0", zIndex: "4" }}>
            <div className="d-flex flex-wrap align-items-center justify-content-end">

                <div className="icons">
                    <div className="d-flex">
                        <div className="icon-1 px-2">
                            <img src={markIcon} alt="" />
                        </div>
                        <div className="icon-1 px-2">
                            <img src={bellIcon} alt="" />
                        </div>
                        <div className="icon-1 px-2">
                            <div className="dropdown">
                                <button className="profile dropdown" data-bs-toggle="dropdown" aria-expanded="false" style={{ borderRadius: "50%", padding: "2px 5px", backgroundColor: "#F0EEEE" }}>
                                    {(() => {
                                        if (fullname === "") { return }
                                        const names = fullname.split(" "); // Split fullname by spaces
                                        const firstName = names[0];
                                        const lastName = names[1];

                                        if (lastName) {
                                            // If there is a last name, take the first letter of both first and last names
                                            return firstName[0].toUpperCase() + lastName[0].toUpperCase();
                                        } else {
                                            // If no last name, take the first two letters of the first name
                                            return firstName[0].toUpperCase() + firstName[1].toUpperCase();
                                        }
                                    })()}
                                </button>
                                <ul class="dropdown-menu">
                                    <li><Link class="dropdown-item" to="/profile">Profile</Link></li>
                                    <li><button class="dropdown-item" onClick={handleLogout} >Logout</button></li>
                                </ul>


                            </div>

                            {/* <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Dropdown button
                            </button>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#">Action</a></li>
                                <li><a class="dropdown-item" href="#">Another action</a></li>
                                <li><a class="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </div> */}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar2
