import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { toast, ToastContainer } from 'react-toastify';
import markIcon from '../images/mark.svg';
import bellIcon from '../images/bell.svg';
import { Link } from 'react-router-dom';
import Navbar2 from './Navbar2';

const Profile = () => {

    useEffect(() => {
        const link = document.createElement('link');
        const link2 = document.createElement('link');
        link.href = 'https://unpkg.com/@webpixels/css@1.1.5/dist/index.css';
        link.rel = 'stylesheet';

        link2.href = 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css';
        link2.rel = 'stylesheet';

        document.head.appendChild(link);
        document.head.appendChild(link2);

        return () => {
            document.head.removeChild(link);
            document.head.removeChild(link2);
        };
    }, []);

    const [userData, setUserData] = useState({});
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const verifyUser = async () => {
        try {
            const response = await fetch(`/auth/get_user_data`, {
                headers: {
                    "Authorization": localStorage.getItem("token")
                }
            });
            const data = await response.json();

            if (data.suc) {
                setUserData(data.auth);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }
    
    useEffect(() => {
        verifyUser();
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = "logout";
    };

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }
        // Log passwords to the console
        console.log("New Password:", newPassword);
        console.log("Confirm Password:", confirmPassword);
    }

    return (
        <div>
            <ToastContainer />

            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />
                <div className="other-screen flex-grow-1 overflow-y-lg-auto">

                    <main className="py-0 pt-0 bg-surface-secondary" style={{ height: "100%", position: "relative" }}>
                        <Navbar2/>

                        <div className="card-header mb-5">
                            <div className="row align-items-center justify-content-between text-center">
                                <div className="col-md-12 mb-0 mb-md-0 text-start">
                                    <div className="row">
                                        <div className="col-6">
                                            <h4>Profile</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid">
                            {/* User Profile Information */}
                            <div className="card mb-4">
                                <div className="card-body">
                                    <h5 className="card-title">User Information</h5>
                                    <p><strong>Full Name:</strong> {userData.full_name}</p>
                                    <p><strong>Email:</strong> {userData.email}</p>
                                    <p><strong>Username:</strong> {userData.username}</p>
                                    <p><strong>Account Created:</strong> {new Date(userData.created_at).toLocaleDateString()}</p>
                                </div>
                            </div>

                            {/* Reset Password Form */}
                            <div className="card mb-4">
                                <div className="card-body">
                                    <h5 className="card-title">Reset Password</h5>
                                    <form onSubmit={handlePasswordSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">New Password</label>
                                            <input 
                                                type="password" 
                                                className="form-control" 
                                                value={newPassword} 
                                                onChange={(e) => setNewPassword(e.target.value)} 
                                                required 
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Confirm New Password</label>
                                            <input 
                                                type="password" 
                                                className="form-control" 
                                                value={confirmPassword} 
                                                onChange={(e) => setConfirmPassword(e.target.value)} 
                                                required 
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary">Reset Password</button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <footer className="text-center py-2 px-8">
                            <p className="px-2">Copyright © 2024 CGC jhanheri. All Rights Reserved</p>
                        </footer>
                    </main>
                </div>
            </div>
        </div>
    );
}

export default Profile;
