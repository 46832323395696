import React, { useState } from 'react';

const AddNoteModal = () => {
    const [formData, setFormData] = useState({
        note: '',
        addedBy: ''
    });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data Submitted:", formData);
        // Submission logic, e.g., API call
    };

    return (
        <div className="modal-body">
            <form onSubmit={handleSubmit}>
                {/* Follow Up Input */}
                <div className="mb-3">
                    <label htmlFor="note" className="form-label">Note</label>
                    <input
                        type="text"
                        className="form-control"
                        id="note"
                        name="note"
                        value={formData.note}
                        onChange={handleChange}
                        placeholder="Add note"
                    />
                </div>

                {/* Created By Input */}
                <div className="mb-3">
                    <label htmlFor="addedBy" className="form-label">Added By</label>
                    <input
                        type="text"
                        className="form-control"
                        id="addedBy"
                        name="addedBy"
                        value={formData.addedBy}
                        onChange={handleChange}
                        placeholder="Enter your name"
                    />
                </div>

                {/* Submit Button */}
                <div className="modal-footer p-0">
                    <button type="button" className="btn btn-secondary mb-0" data-bs-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-primary mb-0">Add Note</button>
                </div>
            </form>
        </div>
    );
};

export default AddNoteModal;
