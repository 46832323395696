import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const DocumentLocker = ({id}) => {
    const [documents, setDocuments] = useState([])

    useEffect(() => {
        getdocs();
    }, [])

    const getdocs = async () => {
        const response = await fetch(`/api/get_lead_docs/${id}`)
        const data1 = await response.json();
        console.log(data1);
        if (data1.suc) {
            setDocuments(data1.data);
        }
        else{
            setDocuments([]);
        }
    }

    return (
        <div>
            <div className="values px-4 py-3">
                <span>
                    <h4>Document Locker</h4>
                </span>
                <hr />

                <div className="documents">
                    <div className="row">
                        {documents.map((element, index) => (
                            <div key={index} className="col-2 text-center">
                                <div className="download">
                                    <Link to={`/${element.url}`} target='_blank' ><i class="fa-solid fa-download"></i></Link>
                                </div>
                                <div className="download">{element.label}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentLocker
