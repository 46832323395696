import React, { useEffect, useState } from 'react'

const Timeline = ({ id, dataOnly }) => {
    const [data, setData] = useState([])

    useEffect(() => {
        getTimeline();
    }, [])

    const getTimeline = async () => {
        const response = await fetch(`/api/lead_timeline/${id}`)
        const data1 = await response.json();
        console.log(data1);
        if (data1.suc) {
            setData(data1.leadActivity);
        }
    }

    const TimelineData = () => {
        return (
            data.map((element, index) => (
                <React.Fragment key={index}>
                    {element.is_open === "1" && (
                        <div className='d-flex mb-5'>
                            <div className="col-2" style={{ width: "90px" }}>
                                <div className="time">{element.open_format_date}</div>
                            </div>
                            <div className="px-4">
                                <div className="icon">
                                    <div className='per-icon highlight' style={{ borderColor: "#007bff" }}>
                                        {element.activity_type === "email" && <i className="fa-regular fa-envelope-open"></i>}
                                        {element.activity_type === "sms" && <i class="fa-regular fa-comment-dots"></i>}
                                    </div>
                                </div>
                            </div>
                            <div className="nothing" style={{ width: "100%" }}>
                                <div className="msg">
                                    <div className="inner-msg">
                                        <span style={{ fontWeight: "500" }}>
                                            {`${element.name} opened ${element.activity_type} on ${element.email} with subject line`}
                                        </span>: <span className='highlight'>{element.subject}</span>
                                        <div className="score px-1" style={element.score !== "0" ? { color: "green" } : {}}>{element.score}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className='d-flex mb-5'>
                        <div className="col-2" style={{ width: "90px" }}>
                            <div className="time">{element.sent_format_date}</div>
                        </div>
                        <div className="px-4">
                            <div className="icon">
                                <div className='per-icon highlight' style={{ borderColor: "#007bff" }}>

                                    {element.activity_type === "email" && <i className="fa-regular fa-envelope"></i>}
                                    {element.activity_type === "sms" && <i class="fa-regular fa-comment-dots"></i>}
                                </div>
                            </div>
                        </div>
                        <div className="nothing" style={{ width: "100%" }}>
                            <div className="msg">
                                <div className="inner-msg">
                                    <span style={{ fontWeight: "500" }}>
                                        {`${element.name} received ${element.activity_type} on ${element.email} with subject line`}
                                    </span>: <span className='highlight'>{element.subject}</span>
                                    <div className="score px-1" style={element.score !== "0" ? { color: "green" } : {}}>{element.score}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ))
        )
    }

    return (
        <div>
            {/* If heading is true, show only the Custom component */}
            {dataOnly ? (
                <TimelineData />
            ) : (
                <div className="values px-4 py-3">
                    <span>
                        <h4>Timeline</h4>
                    </span>
                    <hr />
                    <div className="timelines d-flex flex-column">
                        <TimelineData />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Timeline
