import React, { useState, useEffect } from 'react'
import Navbar from './Navbar'
import { toast, ToastContainer } from 'react-toastify';
import markIcon from '../images/mark.svg';
import bellIcon from '../images/bell.svg';
import AddUser from './AddUser';
import { Link } from 'react-router-dom';
import Navbar2 from './Navbar2';

const RoleManager = () => {

    useEffect(() => {
        const link = document.createElement('link');
        const link2 = document.createElement('link');
        link.href = 'https://unpkg.com/@webpixels/css@1.1.5/dist/index.css';
        link.rel = 'stylesheet';

        link2.href = 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css';
        link2.rel = 'stylesheet';

        document.head.appendChild(link);
        document.head.appendChild(link2);

        return () => {
            document.head.removeChild(link);
            document.head.removeChild(link2);
        };
    }, []);

    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);

    const [checkboxes, setCheckboxes] = useState({});

    useEffect(() => {
        getAssignedPermissions();
    }, [])

    const getAssignedPermissions = async () => {
        const response = await fetch(`/api/assigned_permissions`);
        const data = await response.json();

        console.log(data);

        if (!data.suc) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].permissions[0] !== 0) {
                    for (let j = 0; j < data[i].permissions.length; j++) {
                        let access = data[i].permissions[j] - 1;
                        console.log(i, access);
                        let str = `${i}-${access}`
                        setCheckboxes(prevCheckboxes => ({
                            ...prevCheckboxes,
                            [str]: true
                        }));
                    }
                }
            }
        }
    }

    const getRoles = async () => {
        try {
            const response = await fetch("/api/get_roles")
            const data = await response.json();
            console.log(data);
            if (!data.suc) {
                setRoles(data);
            }
            else {
                toast.error(data.msg);
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    const getPermissions = async () => {
        try {

            const response = await fetch("/api/get_permissions_array")
            const data = await response.json();
            console.log(data);
            if (!data.suc) {
                setPermissions(data);
            }
            else {
                toast.error(data.msg);
            }
        }
        catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getRoles();
        getPermissions();
    }, [])

    const handleCheckboxChange = (roleIndex, permissionIndex) => {
        setCheckboxes(prevState => ({
            ...prevState,
            [`${roleIndex}-${permissionIndex}`]: !prevState[`${roleIndex}-${permissionIndex}`]
        }));
    };


    const handleCreateNewData = () => {
        const checkedData = Object.entries(checkboxes).filter(([key, isChecked]) => isChecked);
        console.log("checked data", checkedData)
        const result = checkedData.map(([key]) => {
            const [roleIndex, permissionIndex] = key.split('-').map(Number);
            return {
                role: roles[roleIndex]?.name,
                permission: permissions[permissionIndex]?.permission_txt,
                index: permissionIndex,
                roleIndex
            };
        });
        console.log(result);
        managePermission(result);
    };

    const managePermission = async (result) => {
        // try{
        const formData = new FormData();

        if (result.length == 0) {
            formData.append('data', 'nothing');
        }
        else {
            for (let i = 0; i < result.length; i++) {
                formData.append(`data[${i}][role_id]`, (result[i].roleIndex) + 1)
                formData.append(`data[${i}][permission_id]`, (result[i].index) + 1)
            }
        }

        console.log([...formData]);

        const response = await fetch("/api/manage_permissions", {
            method: "POST",
            body: formData
        });
        const data = await response.json();
        console.log("data", data);
        if (data.suc) {
            if (data.msg != '') {
                toast.success(data.msg);
            } else {
                toast.success("Data saved successfully");
            }

        }
        else {
            toast.error(data.msg);
        }
        // }
        // catch(error){
        //     toast.error("You don't have the access");
        // }


    }

    const handleAddUsers = () => {
        const modal = new window.bootstrap.Modal(document.getElementById('exampleModal5'));
        modal.show();
    }

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = "logout";
    }

    return (
        <div>
            <ToastContainer />

            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />
                <div className="other-screen flex-grow-1 overflow-y-lg-auto">

                    <main className="py-0 pt-0 bg-surface-secondary" style={{height:"100%", position:"relative"}}>

                        <Navbar2/>

                        <div className="card-header mb-5">
                            <div className="row align-items-center justify-content-between text-center">

                                <div className="col-md-12 mb-0 mb-md-0 text-start">
                                    <div className="row">
                                        <div className="col-6">
                                            <h4>Role Manager</h4>
                                        </div>

                                        <div className="col-6 text-end">
                                            <button type="button" onClick={handleAddUsers} class="btn btn-outline-primary px-2 py-1">Add Users</button>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="d-flex justify-content-between">
                                    <div className="">
                                        <h4>Follow Up & Notes</h4>
                                    </div>

                                    <div>
                                        <button type="button" onClick={handleAddFollowUp} class="btn btn-outline-primary px-2 py-1">Add Follow Up</button>
                                        <button type="button" onClick={handleAddNote} class="btn btn-outline-primary px-2 py-1 ms-3">Add Note</button>
                                    </div>
                                </div> */}

                                {/* <div className="col-md-5 mb-3 mb-md-0">
                                    <div className="row align-items-center justify-content-end">
                                        <div className="col-10 col-md-10">
                                            <div className="row">
                                                <div className="col-4 pe-0 filter-input">

                                                    <select className="form-select my-form-select" aria-label="Default select example">
                                                        <option selected value="">Search By</option>
                                                    </select>

                                                </div>

                                                <div className="col-8 p-0 search-input">
                                                    <form>
                                                        <div className="input-group">
                                                            <input id="myInput" type="text" className="form-control my-form" placeholder="something" aria-label="Recipient's username" aria-describedby="button-addon2" />

                                                            <button className="btn btn-outline-secondary mybtn2" type="submit" id="button-addon2"><i className="fa-solid fa-magnifying-glass"></i></button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2 col-lg-1 ps-0" >
                                            <button className='mybtn'>
                                                <i className="fa-solid fa-xmark"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>


                                <div className='col-md-4'>
                                    <div className='row justify-content-center'>

                                        <div className="col-2">
                                            <button type="button" title='delete' className="my-btn3"><i className="fa-solid fa-trash"></i></button>
                                        </div>

                                        <div className="col-2">
                                            <button className="my-btn3" title='customize columns' type="button">
                                                <i className="fa-solid fa-chart-simple"></i>
                                            </button>
                                        </div>
                                        <div className="col-2">
                                            <button className="my-btn3" title='export'>
                                                <i className="fa-solid fa-file-export"></i>
                                            </button>
                                        </div>
                                        <div className="col-2">
                                            <button className="my-btn3" title='filter'>
                                                <i className="fa-solid fa-filter"></i>
                                            </button>
                                        </div>
                                        <div className="col-2">
                                            <button className="my-btn3" title='import'>
                                                <i className="fa-solid fa-file-import"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        </div>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className='role_heading'>
                                        <h4>Create New Roles Permission </h4>
                                    </div>

                                    <div className='role_manage'>
                                        <h4 className='mb-3'>Select Role</h4>

                                        {/* <select className="form-select mb-3" aria-label="Default select example" style={{ borderRadius: "0" }}>
                                            <option selected value="">select option</option>
                                            <option selected value="">select option</option>
                                            <option selected value="">select option</option>
                                        </select> */}

                                        <div className="card shadow border-0" style={{ borderRadius: "0" }}>
                                            <div className="table-responsive" style={{ minHeight: "unset" }}>
                                                <table className="table table-hover table-nowrap table-bordered">


                                                    <tbody id='myTable'>
                                                        <tr>
                                                            <td><strong>Access</strong></td>

                                                            {roles.map((row, index) => (
                                                                <td key={index}><strong>{row.name}</strong></td>
                                                            ))}

                                                            {/* <td><strong>Add</strong></td>
                                                            <td><strong>Edit</strong></td>
                                                            <td><strong>View</strong></td>
                                                            <td><strong>Delete</strong></td> */}
                                                        </tr>

                                                        {/* {roles.map((row, index) => (
                                                            <tr key={index}>
                                                                <td><strong>{row.name}</strong></td>
                                                                {permissions.map((row, index) => (
                                                                    <td key={index}><form><input type="checkbox" /></form></td>
                                                                ))}
                                                            </tr>
                                                        ))} */}

                                                        {permissions.map((row, permIndex) => (
                                                            <tr key={permIndex}>
                                                                <td><strong>{row.permission_txt}</strong></td>
                                                                {roles.map((role, roleIndex) => (
                                                                    <td key={roleIndex}>
                                                                        <form>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={checkboxes[`${roleIndex}-${permIndex}`]}
                                                                                onChange={() => handleCheckboxChange(roleIndex, permIndex)}
                                                                            />
                                                                        </form>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}

                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='role_heading'>
                                        <button type="button" className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "darkgreen" }} onClick={handleCreateNewData}>Update Permissions</button>
                                    </div>

                                </div>
                                <div className="col-md-4 d-none">
                                    <div className='role_heading'>
                                        <h4>Role Permission List </h4>
                                    </div>

                                    <div className='role_manage'>
                                        <div className="card shadow border-0" style={{ borderRadius: "0" }}>
                                            <div className="table-responsive" style={{ minHeight: "unset" }}>
                                                <table className="table table-hover table-nowrap table-bordered">
                                                    {/* <thead className="thead-light">
                                                        <tr>
                                                            <th>action</th>
                                                        </tr>
                                                    </thead> */}

                                                    <tbody id='myTable'>
                                                        <tr>
                                                            <td><strong>Role Name</strong></td>
                                                            <td><strong>Action</strong></td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <footer className='text-center py-2 px-8' style={{position:"absolute", bottom:"0", width:"100%"}}>
                            <p className='px-2'>Copyright © 2024 CGC jhanheri. All Rights Reserved</p>
                        </footer>
                    </main>
                </div>
            </div>







            <div className="modal fade" id="exampleModal5" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Add a New User</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <AddUser />

                    </div>
                </div>
            </div>

        </div>
    )
}

export default RoleManager
