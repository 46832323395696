import React, { useState, useRef, useEffect } from 'react'

const Modal = ({ selectedRow, setSelectedRow, showSelectedColumns, changeAlert }) => {
    const [flag, setFlag] = useState(false);
    const closeRef = useRef(null);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [course, setCourse] = useState("");
    const [id, setId] = useState("");

    const [isValid, setIsValid] = useState(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const [alert, setAlert] = useState(null);

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [authToken, setAuthToken] = useState(null);
    const [authTokenExpiry, setAuthTokenExpiry] = useState(null);

    useEffect(() => {
        if (selectedRow) {
            setName(selectedRow.name || "");
            setEmail(selectedRow.email || "");
            setNumber(selectedRow.phone || "");
            setState(selectedRow.state || "");
            setCity(selectedRow.city || "");
            setId(selectedRow.id || "");
            setCourse(selectedRow.course || "");
        }
    }, [selectedRow]);

    useEffect(() => {
        getAccessToken();
    }, []);

    const getAccessToken = async () => {
        try {
            const response = await fetch("https://www.universal-tutorial.com/api/getaccesstoken", {
                headers: {
                    "api-token": "QcbmmQtxMFEXBbZN0OnFjG1lpJaOf1FSROjj5K9WMkJwkllhMQBkUIXv70S1aqabX2k",
                    "user-email": "shivduttdhadwal@gmail.com",
                    Accept: "application/json",
                },
            });

            const data = await response.json();
            setAuthToken('Bearer ' + data.auth_token);
            setAuthTokenExpiry(new Date().getTime() + (4 * 60 * 60 * 1000));

        }
        catch (error) {
            console.error('Error fetching access token:', error);
        }
    };

    useEffect(() => {
        if (authToken && authTokenExpiry && new Date().getTime() > authTokenExpiry) {
            getAccessToken();
        }
    }, [authToken, authTokenExpiry]);


    useEffect(() => {
        getStates();
    }, [authToken])

    const getStates = async () => {

        if (!authToken) {
            console.error('AuthToken is null, cannot fetch states');
            return;
        }

        const response = await fetch("https://www.universal-tutorial.com/api/states/india",
            {
                headers: {
                    Authorization: authToken,
                    Accept: "application/json"
                }
            }
        )

        const data = await response.json();
        console.log(data);
        setStates(data);

    }

    const getCities = async (updatedState) => {
        try {
            const response = await fetch(`https://www.universal-tutorial.com/api/cities/${updatedState}`,
                {
                    headers: {
                        Authorization: authToken,
                        Accept: "application/json"
                    }
                }
            )

            const data = await response.json();
            console.log(data);
            setCities(data);
        }
        catch (error) {
            console.error('Error fetching cities:', error);
        }
    }

    
    
    const handleChange1 = (e)=>{
        setName(e.target.value);
    }
    
    const handleChange2 = (e) => {
        setEmail(e.target.value);
        setIsValid(emailRegex.test(e.target.value));
    };

    const handleChange3 = (e)=>{
        setNumber(e.target.value)
    }

    const handleChange5 = (e) => {
        setState(e.target.value);
        setFlag(true);
        getCities(e.target.value);
    }

    const handleChange6 = (e) => {
        setCity(e.target.value);
    }

    

    function counting(num) {
        return String(num).split('').reduce(
            (count, digit) => count + 1, 0
        );
    }

    const editData = async () => {

        if (counting(number) != 10) {
            setAlert({ msg: "Invalid Phone Number", type: "danger" });
            setTimeout(() => {
                setAlert(null);
            }, 2000);
            return;
        }

        if(name===""){
            setAlert({ msg: "Name can not be empty", type: "danger" });
            setTimeout(() => {
                setAlert(null);
            }, 2000);
            return;
        }

        if (isValid) {
            console.log('Email is valid');
        }else{
            setAlert({ msg: "Invalid Email", type: "danger" });
            setTimeout(() => {
                setAlert(null);
            }, 2000);
            return;
        }

        if(selectedRow.email===""){
            setAlert({ msg: "Email can not be empty", type: "danger" });
            setTimeout(() => {
                setAlert(null);
            }, 2000);
            return;
        }

        const formData = new FormData();

        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", number);
        formData.append("state", state);
        formData.append("city", city);
        formData.append("id", id);

        console.log([...formData])

        const response = await fetch(`/api/update`, {
            method: "POST",
            body: formData
        })

        const data = await response.json();
        console.log(data);
        changeAlert(data);

        closeRef.current.click();
    }

    const handleSaveChanges = (e) => {
        e.preventDefault();
        editData();
    }

    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);

    useEffect(() => {
        const handleWheel = (event) => {
            event.preventDefault();
        };

        const inputElement = inputRef.current;
        const inputElement2 = inputRef2.current;
        const inputElement3 = inputRef3.current;
        if (inputElement) {
            inputElement.addEventListener('wheel', handleWheel);
            inputElement.addEventListener('mousewheel', handleWheel);
        }
        if (inputElement2) {
            inputElement2.addEventListener('wheel', handleWheel);
            inputElement2.addEventListener('mousewheel', handleWheel);
        }
        if (inputElement3) {
            inputElement3.addEventListener('wheel', handleWheel);
            inputElement3.addEventListener('mousewheel', handleWheel);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('wheel', handleWheel);
                inputElement.removeEventListener('mousewheel', handleWheel);
            }
            if (inputElement2) {
                inputElement2.removeEventListener('wheel', handleWheel);
                inputElement2.removeEventListener('mousewheel', handleWheel);
            }
            if (inputElement3) {
                inputElement3.removeEventListener('wheel', handleWheel);
                inputElement3.removeEventListener('mousewheel', handleWheel);
            }
        };
    }, []);

    return (
        <div>
            {alert && <div className="form_container"> <div className={`alert alert-${alert.type}`} style={{ display: "fixed" }} id="alertId" role="alert">
                <div className="container">
                    <div className="inside_form_sec">{alert.msg}</div>
                </div>
            </div>
            </div>}

            <div className='modal-body'>
                <form>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={name}
                            onChange={handleChange1}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={email}
                            onChange={handleChange2}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="number" className="form-label">Number</label>
                        <input
                            type="number"
                            className="form-control"
                            id="number"
                            name="number"
                            value={number}
                            onChange={handleChange3}
                            ref={inputRef}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="state" className="form-label">State</label>
                        <select
                            value={state}
                            onChange={handleChange5}
                            className="form-control"
                            id="State"
                        >
                            <option value={state}>{state}</option>
                            {states.map((state, index) => (
                                <option key={index} value={state.state_name}>
                                    {state.state_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="city" className="form-label">City</label>
                        <select
                            value={city}
                            onChange={handleChange6}
                            className="form-control"
                            id="State"
                        >
                            <option value={flag===false?city:""}>{flag===false?city:"Select City"}</option>
                            {cities.map((city, index) => (
                                <option key={index} value={city.city_name}>
                                    {city.city_name}
                                </option>
                            ))}
                        </select>
                    </div>

                </form>
            </div>
            <div className="modal-footer">
                <button type="button" ref={closeRef} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" onClick={handleSaveChanges} >Save changes</button>
            </div>
        </div>
    )
}

export default Modal
