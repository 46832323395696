import React from 'react'
import { useState, useEffect } from 'react';
import Modal from './Modal';
import { toast } from 'react-toastify';

const LeadDetails = ({ formData, applicationData, id }) => {

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        fetch(`/api/get_course`)
            .then(response => response.json())
            .then(data => {
                setCourses(data);
                console.log("courses......", data)
            })
            .catch(error => {
                console.error('Error fetching courses:', error);
            });
    }, []);

    const changeAlert = (data) => {
        if(data.suc){
            toast.success(data.msg);
        }
        else{
            toast.error(data.msg);
        }
    }

    const showSelectedColumns = () => {
        console.log("done");
    }

    const [selectedRow, setSelectedRow] = useState({});

    const handleEditClick = () => {
        setSelectedRow({
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            alternate_phone: applicationData.alternate_phone,
            state: formData.state,
            city: formData.city,
            course: selectedCourse ? selectedCourse.name : null,
            id: id
        });

        const modalId = 'exampleModal';
        const modalElement = document.getElementById(modalId);
        const modalInstance = new window.bootstrap.Modal(modalElement);

        // Add a unique key to the modal to force remount
        modalElement.setAttribute('data-key', Date.now());

        modalInstance.show();
    };

    const selectedCourse = Object.values(courses).find(course => course.id === formData.course);


    return (
        <div>
            <div className="values px-4 py-3">
                <span>
                    <h4>
                        Lead Details

                        <button
                            type="button"
                            className="my-btn3"
                            onClick={() => handleEditClick()}
                        >
                            <i className="fa-regular fa-pen-to-square"></i>
                        </button>
                    </h4>
                </span>
                <hr />
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Form Interested In</strong></div>
                    <div className="">: {formData.form_applied || "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Email Address</strong></div>
                    <div className="">: {formData.email || "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Mobile Number</strong></div>
                    <div className="">: {formData.phone || "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Alternate Mobile Number</strong></div>
                    <div className="">: {applicationData.alternate_phone || "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Name</strong></div>
                    <div className="">: {formData.name || "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>State</strong></div>
                    <div className="">: {formData.state || "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>City</strong></div>
                    <div className="">: {formData.city || "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Course</strong></div>
                    <div className="">: {selectedCourse ? selectedCourse.name : "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Lead Stage</strong></div>
                    <div className="">: {formData.lead_stage || "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Lead Sub Stage</strong></div>
                    <div className="">: {formData.lead_sub_stage || "NA"} </div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Forms Applied</strong></div>
                    <div className=""><span className='highlight'>: {formData.form_applied || "NA"}</span></div>
                </div>
            </div>



            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Edit Student Details</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        {selectedRow && (<Modal changeAlert={changeAlert} selectedRow={selectedRow} setSelectedRow={setSelectedRow} showSelectedColumns={showSelectedColumns} />)}

                    </div>
                </div>
            </div>

        </div>
    )
}

export default LeadDetails
