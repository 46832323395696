import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Slider = ({ activeIndex, step }) => {
  // Convert step to a number (since it's passed as a string)
  const currentStep = parseInt(step);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
    else {
      verifyUser();
      // navigateToStep(currentStep);
    }

    // eslint-disable-next-line
  }, [])

  const checkRole = (id) => {
    if (id === "1" || id === "2") {
      navigate('/');
    }
    else {
      setLoading(false);
    }
  }

  const verifyUser = async () => {
    setLoading(true); // Set loading to true when fetching starts

    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`/auth/get_user_data`, {
        headers: {
          "Authorization": localStorage.getItem("token")
        }
      });
      const data = await response.json();
      console.log("auth_data", data);
      if (data.suc) {
        console.log("Authentication completed");
        checkRole(data.auth.role_id);
      } else {
        localStorage.removeItem('token');
        navigate("/login");
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      localStorage.removeItem('token');
      navigate("/login");
    }
  }

  return (
    <section>
      <div className="slider">
        <div
          className="d-flex myscroller"
          style={{
            overflow: 'auto',
          }}
        >
          {/* Step 1: Course and Personal Details */}
          <div className="item">
            {currentStep >= 1 ? (
              <Link to='/form' className={`circle ${activeIndex === 0 ? 'active' : ''}`}>
                <i className="fa-solid fa-user"></i>
              </Link>
            ) : (
              <div className="circle2 disabled">
                <i className="fa-solid fa-user"></i>
              </div>
            )}
            <h6 className='d-none d-md-block'>Course and Personal Details</h6>
          </div>

          {/* Step 2: Educational Qualification Details */}
          <div className="item">
            {currentStep >= 2 ? (
              <Link to='/form/educational_details' className={`circle ${activeIndex === 1 ? 'active' : ''}`}>
                <i className="fa-solid fa-file-lines"></i>
              </Link>
            ) : (
              <div className="circle2 disabled">
                <i className="fa-solid fa-file-lines"></i>
              </div>
            )}
            <h6 className='d-none d-md-block'>Educational Qualification Details</h6>
          </div>

          {/* Step 3: Upload Documents */}
          <div className="item">
            {currentStep >= 3 ? (
              <Link to='/form/documents' className={`circle ${activeIndex === 2 ? 'active' : ''}`}>
                <i className="fa-solid fa-paperclip"></i>
              </Link>
            ) : (
              <div className="circle2 disabled">
                <i className="fa-solid fa-paperclip"></i>
              </div>
            )}
            <h6 className='d-none d-md-block'>Upload Documents</h6>
          </div>

          {/* Step 4: Declaration */}
          <div className="item">
            {currentStep >= 4 ? (
              <Link to='/form/declaration' className={`circle ${activeIndex === 3 ? 'active' : ''}`}>
                <i className="fa-regular fa-square-check"></i>
              </Link>
            ) : (
              <div className="circle2 disabled">
                <i className="fa-regular fa-square-check"></i>
              </div>
            )}
            <h6 className='d-none d-md-block'>Declaration</h6>
          </div>

          {/* Step 5: Payment */}
          <div className="item">
            {currentStep >= 5 ? (
              <Link to='/form/payment' className={`circle ${activeIndex === 4 ? 'active' : ''}`}>
                <i className="fa-regular fa-credit-card"></i>
              </Link>
            ) : (
              <div className="circle2 disabled">
                <i className="fa-regular fa-credit-card"></i>
              </div>
            )}
            <h6 className='d-none d-md-block'>Payment</h6>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slider;
