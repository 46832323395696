import React, { useEffect } from 'react';

const ProgressBar = ({ formData }) => {
  // Calculate the number of fields filled
  // const totalFields = Object.keys(formData).length - 25;
  // let filledFields = Object.values(formData).filter(value => value !== null && value !== '').length + 18 - 25;

  const excludedFields = [
    'alternate_phone', 'father_occupation', 'mother_occupation', 'twelfth_institute_name',
    'twelfth_board', 'twelfth_year_of_passing', 'twelfth_result_status', 'twelfth_marking_scheme',
    'twelfth_obtained_marks', 'twelfth_max_marks', 'twelfth_roll_no',
    'diploma_institute_name', 'diploma_board', 'diploma_year_of_passing',
    'diploma_result_status', 'diploma_marking_scheme', 'diploma_obtained_marks',
    'diploma_max_marks', 'step', 'completion_status', 'id', 'user_id',
    'lead_id', 'created', 'modified'
  ];

  const totalFields = Object.keys(formData).filter(key => !excludedFields.includes(key)).length;

  let filledFields = Object.entries(formData)
    .filter(([key, value]) => !excludedFields.includes(key) && value !== null && value !== '')
    .length;


  const progress = (filledFields / totalFields) * 100;
  console.log("cal", totalFields, filledFields);

  useEffect(() => {
    console.log("count", totalFields, filledFields)
  }, [])

  // Styles for the progress bar and the completion div
  const progressBarContainer = {
    width: '100%',
    height: '10px',
    backgroundColor: '#e0e0e0',
    borderRadius: '5px',
    position: 'relative',
  };

  const progressBar = {
    width: `${progress}%`,
    height: '100%',
    backgroundColor: '#3b82f6',
    borderRadius: '5px',
  };

  const progressText = {
    position: 'absolute',
    top: '-25px',
    left: `${progress}%`,
    transform: 'translateX(-50%)',
    color: '#3b82f6',
    fontWeight: 'bold',
  };

  return (
    <div style={{ margin: '20px 0' }}>
      <div style={progressBarContainer}>
        <div style={progressBar}></div>
        <div style={progressText}>{Math.round(progress)}%</div>
      </div>
    </div>
  );
};

export default ProgressBar;
