import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Slider from './Slider';
import { ToastContainer, toast } from 'react-toastify';
import ProgressBar from './ProgressBar';

import io from 'socket.io-client';
const SOCKET_SERVER_URL = 'https://socket.cgc.ac.in/';

const EducationalDetails = () => {

    const excludedFields = [
        'alternate_phone', 'father_occupation', 'mother_occupation', 'twelfth_institute_name',
        'twelfth_board', 'twelfth_year_of_passing', 'twelfth_result_status', 'twelfth_marking_scheme',
        'twelfth_obtained_marks', 'twelfth_max_marks', 'twelfth_roll_no',
        'diploma_institute_name', 'diploma_board', 'diploma_year_of_passing',
        'diploma_result_status', 'diploma_marking_scheme', 'diploma_obtained_marks',
        'diploma_max_marks', 'step', 'completion_status', 'id', 'user_id',
        'lead_id', 'created', 'modified'
    ];

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        school_college_name: '',
        school_college_state: '',
        school_college_city: '',
        tenth_institute_name: '',
        tenth_board: '',
        tenth_year_of_passing: '',
        twelfth_result_status: '',
        tenth_marking_scheme: '',
        tenth_obtained_marks: '',
        tenth_max_marks: '',
        twelfth_institute_name: '',
        twelfth_board: '',
        twelfth_year_of_passing: '',
        diploma_result_status: '',
        twelfth_marking_scheme: '',
        twelfth_obtained_marks: '',
        twelfth_max_marks: '',
        diploma_institute_name: '',
        diploma_board: '',
        diploma_year_of_passing: '',
        diploma_marking_scheme: '',
        diploma_obtained_marks: '',
        diploma_max_marks: '',
        qualifying_exam: '',
        twelfth_roll_no: '',
    });

    const [studentId, setStudentId] = useState('');


    const [authToken, setAuthToken] = useState(null);
    const [authTokenExpiry, setAuthTokenExpiry] = useState(null);

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        getAccessToken();
    }, []);

    const getAccessToken = async () => {
        try {
            const response = await fetch("https://www.universal-tutorial.com/api/getaccesstoken", {
                headers: {
                    "api-token": "QcbmmQtxMFEXBbZN0OnFjG1lpJaOf1FSROjj5K9WMkJwkllhMQBkUIXv70S1aqabX2k",
                    "user-email": "shivduttdhadwal@gmail.com",
                    Accept: "application/json",
                },
            });

            const data = await response.json();
            setAuthToken('Bearer ' + data.auth_token);
            setAuthTokenExpiry(new Date().getTime() + (4 * 60 * 60 * 1000));

        }
        catch (error) {
            console.error('Error fetching access token:', error);
        }
    };

    useEffect(() => {
        if (authToken && authTokenExpiry && new Date().getTime() > authTokenExpiry) {
            getAccessToken();
        }
    }, [authToken, authTokenExpiry]);


    useEffect(() => {
        getStates();
    }, [authToken])

    const getStates = async () => {

        if (!authToken) {
            console.error('AuthToken is null, cannot fetch states');
            return;
        }

        const response = await fetch("https://www.universal-tutorial.com/api/states/india",
            {
                headers: {
                    Authorization: authToken,
                    Accept: "application/json"
                }
            }
        )

        const data = await response.json();
        console.log(data);
        setStates(data);
    }

    const getCities = async (updatedState) => {
        try {
            const response = await fetch(`https://www.universal-tutorial.com/api/cities/${updatedState}`,
                {
                    headers: {
                        Authorization: authToken,
                        Accept: "application/json"
                    }
                }
            )

            const data = await response.json();
            console.log(data);
            setCities(data);
        }
        catch (error) {
            console.error('Error fetching cities:', error);
        }
    }

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        // Fetch courses from the API
        fetch('/api/get_course')
            .then(response => response.json())
            .then(data => {
                setCourses(data); // Assuming the API returns an array of course objects
            })
            .catch(error => {
                console.error('Error fetching courses:', error);
            });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "school_college_state") {
            getCities(value);
        }
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);

        const totalFields = Object.keys(formData).filter(key => !excludedFields.includes(key)).length;
        let filledFields = Object.entries(formData)
            .filter(([key, value]) => !excludedFields.includes(key) && value !== null && value !== '')
            .length;

        const progress = (filledFields / totalFields) * 100;
        const progress2 = Math.round(progress);
        console.log("field counts", totalFields, filledFields, progress);

        try {
            const response = await fetch(`/api/student_application?validate=validate_form&step=2&completion_status=${progress2}`, {
                method: "POST",
                body: JSON.stringify(formData)
            });
            const data = await response.json();
            console.log(data);
            if (data.suc) {
                toast.success(data.msg);
                navigate('/form/documents')
            }
            else {
                toast.error(data.msg);
            }
        }
        catch (error) {
            toast.error("something went wrong");
            console.error(error);
        }
    };

    const handleMouseOut = () => {
        handleSaveData(true);
    }

    useEffect(() => {
        if (!studentId) return;
    
        const socket = io(SOCKET_SERVER_URL);
    
        // Emit 'online' status when component mounts
        socket.emit('student_status', { status: 'online', user_id: studentId });
    
        // Emit 'offline' status when component unmounts
        return () => {
          socket.emit('student_status', { status: 'offline', user_id: studentId });
          socket.disconnect(); // Disconnect socket when the component unmounts
        };
      }, [studentId]);
    
      const sendValueToServer = (inputValue) => {
        const socket = io(SOCKET_SERVER_URL);
        const id = studentId; // student user_id, you can use it 
        console.log("dataaaaa", id, inputValue);
        socket.emit('set_value', { value: inputValue, user_id: id }); // Emit the 'set_value' event with the input data
      };

    const handleSaveData = async (mouseOut) => {
        console.log(formData);



        const totalFields = Object.keys(formData).filter(key => !excludedFields.includes(key)).length;
        let filledFields = Object.entries(formData)
            .filter(([key, value]) => !excludedFields.includes(key) && value !== null && value !== '')
            .length;

        const progress = (filledFields / totalFields) * 100;
        const progress2 = Math.round(progress);
        console.log("field counts", totalFields, filledFields, progress);

        sendValueToServer(progress2);

        try {
            const response = await fetch(`/api/student_application?completion_status=${progress2}`, {
                method: "POST",
                body: JSON.stringify(formData)
            })
            const data = await response.json();
            console.log(data);
            if (!mouseOut) {
                if (data.suc) {
                    toast.success(data.msg);
                }
                else {
                    toast.error(data.msg);
                }
            }
        }
        catch (error) {
            toast.error("something went wrong");
            console.error(error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = "/logout";
    };

    useEffect(() => {
        getStudentData();
    }, [])

    const getStudentData = async () => {
        const response = await fetch("/api/fetch_student_application");
        const data1 = await response.json();
        console.log("student data", data1);
        if (data1) {
            setFormData(data1.data[0]);
            setStudentId(data1.data.lead_id);
        }
    }

    const handleBack = () => {
        navigate('/form')
    }

    return (
        <div>
            <ToastContainer />
            <header>
                <div className="container5 fixed-header py-2 px-3" style={{ boxShadow: "rgb(194 197 201) 0px 4px 20px" }}>
                    <nav>
                        <div className="navbar-header d-flex justify-content-between align-items-center">
                            <Link to='/form'>
                                <img src="https://cdn.npfs.co/uploads/college/image/5f2a94bfe915e036895412_Webp.net_resizeimage.png" style={{ width: "10rem" }} alt="Logo" />
                            </Link>
                            <ul className='m-0 p-0' style={{ listStyle: "none" }}>
                                <li>
                                    <button type='button' className="btn btn-primary" onClick={handleLogout} title='logout'>
                                        <i className="fa-solid fa-arrow-right-from-bracket text-start"></i> Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>

            <div className="container mb-4" style={{ marginTop: "6.5rem" }}>
                <div>
                    <Slider activeIndex={1} step={formData.step} />
                </div>

                <div>
                    <ProgressBar formData={formData} step={formData.step} />
                </div>
            </div>

            <div className="container my-5 mt-0">
                <div className="panel">
                    <div className="panel-body">
                        <div className="row">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12 heading">
                                        <h5>Academic Details</h5>
                                    </div>

                                    <div className="col-12 course">
                                        <label className="form-label" htmlFor="tenth_institute_name">10th Details</label>
                                        <table className="table table-bordered edu-table">
                                            <thead className='custom-display'>
                                                <tr>
                                                    <th></th>
                                                    <th>Institute Name <span className='star'>*</span></th>
                                                    <th>Board <span className='star'>*</span></th>
                                                    <th>Year of Passing <span className='star'>*</span></th>
                                                    <th>Marking Scheme <span className='star'>*</span></th>
                                                    <th>Obtained Marks/ Percentage/ CGPA/Grade <span className='star'>*</span></th>
                                                    <th>Maximum Marks/ Percentage/ CGPA/Grade <span className='star'>*</span></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='d-none d-lg-table-cell'>
                                                        <p>10th</p>
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        <label className="form-label d-block d-lg-none"> Institute Name <span className='star'>*</span></label>
                                                        <input type="text" id='tenth_institute_name' name='tenth_institute_name' className='form-input' value={formData.tenth_institute_name} required onChange={handleChange} onBlur={handleMouseOut} />
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        <label className="form-label d-block d-lg-none"> Board <span className='star'>*</span></label>
                                                        <select className="my-form-select3" id='tenth_board' name='tenth_board' value={formData.tenth_board} required onChange={handleChange} onBlur={handleMouseOut} aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            <option value="CBSC">CBSC</option>
                                                            <option value="Haryana Board">Haryana Board</option>
                                                        </select>
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        <label className="form-label d-block d-lg-none"> Year of Passing <span className='star'>*</span></label>
                                                        <select
                                                            id="tenth_year_of_passing"
                                                            name="tenth_year_of_passing"
                                                            className="form-input"
                                                            value={formData.tenth_year_of_passing}
                                                            required onChange={handleChange} onBlur={handleMouseOut}
                                                        >
                                                            <option value="">Select</option>
                                                            {Array.from(new Array(50), (v, i) => (
                                                                <option key={i} value={new Date().getFullYear() - i}>
                                                                    {new Date().getFullYear() - i}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        <label className="form-label d-block d-lg-none"> Marking Scheme <span className='star'>*</span></label>
                                                        <select className="my-form-select3" id='tenth_marking_scheme' name='tenth_marking_scheme' value={formData.tenth_marking_scheme} required onChange={handleChange} onBlur={handleMouseOut} aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            <option value="Percentage">Percentage</option>
                                                            <option value="CGPA">CGPA</option>
                                                            <option value="Marks">Marks</option>
                                                            <option value="Grade">Grade</option>
                                                        </select>
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        <label className="form-label d-block d-lg-none"> Obtained Marks/ Percentage/ CGPA/Grade <span className='star'>*</span></label>
                                                        <input type="text" id='tenth_obtained_marks' name='tenth_obtained_marks' className='form-input' value={formData.tenth_obtained_marks} required onChange={handleChange} onBlur={handleMouseOut} />
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        <label className="form-label d-block d-lg-none"> Maximum Marks/ Percentage/ CGPA/Grade <span className='star'>*</span></label>
                                                        <input type="text" id='tenth_max_marks' name='tenth_max_marks' className='form-input' value={formData.tenth_max_marks} required onChange={handleChange} onBlur={handleMouseOut} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-md-6 mb-md-4 mb-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="qualifying_exam">Name of the Qualifying Exam <span className='star'>*</span></label>
                                            <select className="my-form-select3" id='qualifying_exam' name='qualifying_exam' value={formData.qualifying_exam} required onChange={handleChange} onBlur={handleMouseOut} aria-label="Default select example">
                                                <option value="">Select</option>
                                                <option value="12th">12th</option>
                                                <option value="Diploma">Diploma</option>
                                                <option value="Both">Both</option>
                                            </select>
                                        </div>
                                    </div>

                                    {(formData.qualifying_exam === "12th" || formData.qualifying_exam === "Both") && <div className="col-12 course">
                                        <label className="form-label" htmlFor="twelfth_institute_name">12th Details</label>
                                        <table className="table table-bordered edu-table">
                                            <thead className='custom-display'>
                                                <tr>
                                                    <th></th>
                                                    <th>Institute Name <span className='star'>*</span></th>
                                                    <th>Board <span className='star'>*</span></th>
                                                    <th>Year of Passing <span className='star'>*</span></th>
                                                    <th>Result Status <span className='star'>*</span></th>
                                                    <th>Marking Scheme {formData.twelfth_result_status === "Declared" && <span className='star'>*</span>}</th>
                                                    <th>Obtained Marks/ Percentage/ CGPA/Grade {formData.twelfth_result_status === "Declared" && <span className='star'>*</span>}</th>
                                                    <th>Maximum Marks/ Percentage/ CGPA/Grade {formData.twelfth_result_status === "Declared" && <span className='star'>*</span>}</th>
                                                    <th>Roll No.</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='d-none d-lg-table-cell'>
                                                        <p>12th</p>
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        <label className="form-label d-block d-lg-none"> Institute Name <span className='star'>*</span></label>
                                                        <input type="text" id='twelfth_institute_name' name='twelfth_institute_name' className='form-input' value={formData.twelfth_institute_name} required onChange={handleChange} onBlur={handleMouseOut} />
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        <label className="form-label d-block d-lg-none"> Board <span className='star'>*</span></label>
                                                        <select className="my-form-select3" id='twelfth_board' name='twelfth_board' value={formData.twelfth_board} required onChange={handleChange} onBlur={handleMouseOut} aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            <option value="CBSC">CBSC</option>
                                                            <option value="Haryana Board">Haryana Board</option>
                                                        </select>
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        <label className="form-label d-block d-lg-none"> Year of Passing <span className='star'>*</span></label>
                                                        <select
                                                            id="twelfth_year_of_passing"
                                                            name="twelfth_year_of_passing"
                                                            className="form-input"
                                                            value={formData.twelfth_year_of_passing}
                                                            required onChange={handleChange} onBlur={handleMouseOut}
                                                        >
                                                            <option value="">Select Year</option>
                                                            {Array.from(new Array(50), (v, i) => (
                                                                <option key={i} value={new Date().getFullYear() - i}>
                                                                    {new Date().getFullYear() - i}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        <label className="form-label d-block d-lg-none"> Result Status <span className='star'>*</span></label>
                                                        <select className="my-form-select3" id='twelfth_result_status' name='twelfth_result_status' value={formData.twelfth_result_status} required onChange={handleChange} onBlur={handleMouseOut} aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            <option value="Awaited">Awaited</option>
                                                            <option value="Declared">Declared</option>
                                                        </select>
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        {formData.twelfth_result_status === "Declared" && <label className="form-label d-block d-lg-none"> Marking Scheme <span className='star'>*</span></label>}
                                                        {formData.twelfth_result_status === "Declared" && <select className="my-form-select3" id='twelfth_marking_scheme' name='twelfth_marking_scheme' value={formData.twelfth_marking_scheme} required onChange={handleChange} onBlur={handleMouseOut} aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            <option value="Percentage">Percentage</option>
                                                            <option value="CGPA">CGPA</option>
                                                            <option value="Marks">Marks</option>
                                                            <option value="Grade">Grade</option>
                                                        </select>}
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        {formData.twelfth_result_status === "Declared" && <label className="form-label d-block d-lg-none"> Obtained Marks/ Percentage/ CGPA/Grade <span className='star'>*</span></label>}
                                                        {formData.twelfth_result_status === "Declared" &&
                                                            <input type="text" id='twelfth_obtained_marks' name='twelfth_obtained_marks' className='form-input' value={formData.twelfth_obtained_marks} required onChange={handleChange} onBlur={handleMouseOut} />}
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        {formData.twelfth_result_status === "Declared" && <label className="form-label d-block d-lg-none"> Maximum Marks/ Percentage/ CGPA/Grade <span className='star'>*</span></label>}
                                                        {formData.twelfth_result_status === "Declared" && <input type="text" id='twelfth_max_marks' name='twelfth_max_marks' className='form-input' value={formData.twelfth_max_marks} required onChange={handleChange} onBlur={handleMouseOut} />}
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        {formData.twelfth_result_status === "Declared" && <label className="form-label d-block d-lg-none"> Roll No. <span className='star'>*</span></label>}
                                                        {formData.twelfth_result_status === "Declared" && <input type="number" id='twelfth_roll_no' name='twelfth_roll_no' className='form-input' value={formData.twelfth_roll_no} onChange={handleChange} onBlur={handleMouseOut} />}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>}

                                    {(formData.qualifying_exam === "Diploma" || formData.qualifying_exam === "Both") && <div className="col-12 course">
                                        <label className="form-label" htmlFor="diploma_institute_name">Diploma Details</label>
                                        <table className="table table-bordered edu-table">
                                            <thead className='custom-display'>
                                                <tr>
                                                    <th></th>
                                                    <th>Institute Name <span className='star'>*</span></th>
                                                    <th>Board <span className='star'>*</span></th>
                                                    <th>Year of Passing <span className='star'>*</span></th>
                                                    <th>Result Status <span className='star'>*</span></th>
                                                    <th>Marking Scheme {formData.diploma_result_status === "Declared" && <span className='star'>*</span>}</th>
                                                    <th>Obtained Marks/ Percentage/ CGPA/Grade {formData.diploma_result_status === "Declared" && <span className='star'>*</span>}</th>
                                                    <th>Maximum Marks/ Percentage/ CGPA/Grade {formData.diploma_result_status === "Declared" && <span className='star'>*</span>}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='d-none d-lg-table-cell'>
                                                        <p>Diploma</p>
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        <label className="form-label d-block d-lg-none"> Institute Name <span className='star'>*</span></label>
                                                        <input type="text" id='diploma_institute_name' name='diploma_institute_name' className='form-input' value={formData.diploma_institute_name} required onChange={handleChange} onBlur={handleMouseOut} />
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        <label className="form-label d-block d-lg-none"> Board <span className='star'>*</span></label>
                                                        <select className="my-form-select3" id='diploma_board' name='diploma_board' value={formData.diploma_board} required onChange={handleChange} onBlur={handleMouseOut} aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            <option value="CBSC">CBSC</option>
                                                            <option value="Haryana Board">Haryana Board</option>
                                                        </select>
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        <label className="form-label d-block d-lg-none"> Year of Passing <span className='star'>*</span></label>
                                                        <select
                                                            id="diploma_year_of_passing"
                                                            name="diploma_year_of_passing"
                                                            className="form-input"
                                                            value={formData.diploma_year_of_passing}
                                                            required onChange={handleChange} onBlur={handleMouseOut}
                                                        >
                                                            <option value="">Select Year</option>
                                                            {Array.from(new Array(50), (v, i) => (
                                                                <option key={i} value={new Date().getFullYear() - i}>
                                                                    {new Date().getFullYear() - i}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        <label className="form-label d-block d-lg-none"> Result Status <span className='star'>*</span></label>
                                                        <select className="my-form-select3" id='diploma_result_status' name='diploma_result_status' value={formData.diploma_result_status} required onChange={handleChange} onBlur={handleMouseOut} aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            <option value="Awaited">Awaited</option>
                                                            <option value="Declared">Declared</option>
                                                        </select>
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        {formData.diploma_result_status === "Declared" && <label className="form-label d-block d-lg-none"> Marking Scheme <span className='star'>*</span></label>}
                                                        {formData.diploma_result_status === "Declared" && <select className="my-form-select3" id='diploma_marking_scheme' name='diploma_marking_scheme' value={formData.diploma_marking_scheme} required onChange={handleChange} onBlur={handleMouseOut} aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            <option value="Percentage">Percentage</option>
                                                            <option value="CGPA">CGPA</option>
                                                            <option value="Marks">Marks</option>
                                                            <option value="Grade">Grade</option>
                                                        </select>}
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        {formData.diploma_result_status === "Declared" && <label className="form-label d-block d-lg-none"> Obtained Marks/ Percentage/ CGPA/Grade <span className='star'>*</span></label>}
                                                        {formData.diploma_result_status === "Declared" && <input type="text" id='diploma_obtained_marks' name='diploma_obtained_marks' className='form-input' value={formData.diploma_obtained_marks} required onChange={handleChange} onBlur={handleMouseOut} />}
                                                    </td>
                                                    <td className='d-block d-lg-table-cell'>
                                                        {formData.diploma_result_status === "Declared" && <label className="form-label d-block d-lg-none"> Maximum Marks/ Percentage/ CGPA/Grade <span className='star'>*</span></label>}
                                                        {formData.diploma_result_status === "Declared" && <input type="text" id='diploma_max_marks' name='diploma_max_marks' className='form-input' value={formData.diploma_max_marks} required onChange={handleChange} onBlur={handleMouseOut} />}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>}
                                </div>

                                <div className="line">
                                    <hr style={{ border: 'none', borderTop: '1px dashed black' }} />
                                </div>

                                <div className="row align-items-center">
                                    <div className="col-md-12 heading">
                                        <h5>Name and Address of Last Attended School/College</h5>
                                    </div>

                                    <div className="col-md-4 mb-md-4 mb-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="school_college_name">School / College Name <span className='star'>*</span></label>
                                            <input type="text" id='school_college_name' name='school_college_name' className='form-input' placeholder="Enter Your School / College Name" value={formData.school_college_name} required onChange={handleChange} onBlur={handleMouseOut} />
                                        </div>
                                    </div>

                                    <div className="col-md-4 mb-md-4 mb-2 course dropdown">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="school_college_state">School / College State <span className='star'>*</span></label>
                                            <select className="my-form-select3" id='school_college_state' name='school_college_state' value={formData.school_college_state} required onChange={handleChange} onBlur={handleMouseOut} aria-label="Default select example">

                                                <option value="">State</option>
                                                {states.map((state, index) => (
                                                    <option key={index} value={state.state_name}>
                                                        {state.state_name}
                                                    </option>
                                                ))}

                                                {/* <option value="">Select</option>
                                                <option value="Haryana">Haryana</option>
                                                <option value="Punjab">Punjab</option>
                                                <option value="Delhi">Delhi</option> */}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4 mb-md-4 mb-2 course dropdown">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="school_college_city">School / College City <span className='star'>*</span></label>
                                            <select className="my-form-select3" id='school_college_city' name='school_college_city' value={formData.school_college_city} required onChange={handleChange} onBlur={handleMouseOut} aria-label="Default select example">
                                                <option value="">City</option>
                                                {cities.map((city, index) => (
                                                    <option key={index} value={city.city_name}>
                                                        {city.city_name}
                                                    </option>
                                                ))}
                                                {/* <option value="">Select</option>
                                                <option value="Chandigarh">Chandigarh</option>
                                                <option value="Delhi">Delhi</option>
                                                <option value="Mumbai">Mumbai</option> */}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-none d-sm-block'>
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <button type='button' onClick={handleBack} className='btn btn-secondary me-2' style={{ borderRadius: "unset" }}>BACK</button>
                                            <button type='button' onClick={() => { handleSaveData(false) }} className='btn btn-secondary' style={{ borderRadius: "unset" }}>SAVE & EXIT</button>
                                        </div>
                                        <div className="col-6 text-end">
                                            <button type='submit' className='btn btn-primary' style={{ borderRadius: "unset" }}>NEXT</button>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-block d-sm-none'>
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-3">
                                            <button type='button' onClick={handleBack} className='btn btn-success' style={{ borderRadius: "50%" }}><i class="fa-solid fa-chevron-left"></i></button>
                                        </div>
                                        <div className="col-6 text-center">Step 2 of 5</div>
                                        <div className="col-3 text-end">
                                            <button type='submit' className='btn btn-success' style={{ borderRadius: "50%" }}><i class="fa-solid fa-chevron-right"></i></button>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <button type='button' onClick={() => { handleSaveData(false) }} className='btn btn-outline-secondary' style={{ borderRadius: "unset", width: "100%" }}>SAVE & EXIT</button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <footer>
                <div className="footer text-end p-3" style={{ backgroundColor: "#E5E5E5" }}>
                    <img src="https://cresttechnosoft.com/wp-content/uploads/2024/08/logo-3.svg" style={{ width: "8rem" }} alt="Footer Logo" />
                </div>
            </footer>
        </div>
    );
};

export default EducationalDetails;
