import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Slider from './Slider';
import { ToastContainer, toast } from 'react-toastify';
import ProgressBar from './ProgressBar';

const Declaration = () => {
    const navigate = useNavigate();

    const today = new Date();
    const formattedDate = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;


    const [formData, setFormData] = useState({
        name: '',
        father_name: '',
        today: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch(`/api/student_application?step=4&action=update_step`, {
            method: "POST",
            body: JSON.stringify({something:"update", id:formData.id})
        })
        const data = await response.json();
        console.log(data);
        navigate('/form/payment');
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = "/logout";
    };

    useEffect(() => {
        getStudentData();
    }, [])

    const getStudentData = async () => {
        const response = await fetch("/api/fetch_student_application");
        const data1 = await response.json();
        console.log("student data", data1);
        if (data1) {
            setFormData(data1.data[0]);
        }
    }

    const handleBack = () => {
        navigate('/form/documents');
    }


    return (
        <div>
            <ToastContainer />
            <header>
                <div className="container5 fixed-header py-2 px-3" style={{ boxShadow: "rgb(194 197 201) 0px 4px 20px" }}>
                    <nav>
                        <div className="navbar-header d-flex justify-content-between align-items-center">
                            <Link to='/form'>
                                <img src="https://cdn.npfs.co/uploads/college/image/5f2a94bfe915e036895412_Webp.net_resizeimage.png" style={{ width: "10rem" }} alt="Logo" />
                            </Link>
                            <ul className='m-0 p-0' style={{ listStyle: "none" }}>
                                <li>
                                    <button type='button' className="btn btn-primary" onClick={handleLogout} title='logout'>
                                        <i className="fa-solid fa-arrow-right-from-bracket text-start"></i> Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>

            <div className="container mb-4" style={{ marginTop: "6.5rem" }}>
                <div>
                    <Slider activeIndex={3} step={formData.step} />
                </div>

                <div>
                    <ProgressBar formData={formData} />
                </div>
            </div>

            <div className="container my-5 mt-0">
                <div className="panel">
                    <div className="panel-body">
                        <div className="row">
                            <form onSubmit={handleSubmit}>
                                <div className="row">

                                    <div className="col-md-12 heading">
                                        <h5 className='mb-4'>Declaration</h5>
                                        <p>I certify that the information submitted by me in support of this application, is true to the best of knowledge and belief. I understand that in the event of any information being found false or incorrect, my admission is liable to be rejected / cancelled at any stage of the program. I undertake to abide by the disciplinary rules and regulations of the institute.</p>
                                    </div>

                                    <div className="col-md-6 mb-md-4 mb-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="name">Applicant Name <span className='star'>*</span></label>
                                            <input type="text" value={formData.name} disabled id='name' name='name' className='form-input' />
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-md-4 mb-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="parent_name">Parent Name <span className='star'>*</span></label>
                                            <input type="text" value={formData.father_name} disabled id='parent_name' name='parent_name' className='form-input' />
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-md-4 mb-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="date">Date <span className='star'>*</span></label>
                                            <input type="text" value={formattedDate} disabled id='date' name='date' className='form-input' />
                                        </div>
                                    </div>
                                </div>

                                <div className='d-none d-sm-block'>
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <button type='button' onClick={handleBack} className='btn btn-secondary me-2' style={{ borderRadius: "unset" }}>BACK</button>
                                            <button type='button' className='btn btn-secondary' style={{ borderRadius: "unset" }}>SAVE & EXIT</button>
                                        </div>
                                        <div className="col-6 text-end">
                                            <button type='submit' className='btn btn-primary' style={{ borderRadius: "unset" }}>NEXT</button>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-block d-sm-none'>
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-3">
                                            <button type='button' onClick={handleBack} className='btn btn-success' style={{ borderRadius: "50%" }}><i class="fa-solid fa-chevron-left"></i></button>
                                        </div>
                                        <div className="col-6 text-center">Step 4 of 5</div>
                                        <div className="col-3 text-end">
                                            <button type='submit' className='btn btn-success' style={{ borderRadius: "50%" }}><i class="fa-solid fa-chevron-right"></i></button>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <button type='button' className='btn btn-outline-secondary' style={{ borderRadius: "unset", width: "100%" }}>SAVE & EXIT</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <footer>
                <div className="footer text-end p-3" style={{ backgroundColor: "#E5E5E5" }}>
                    <img src="https://cresttechnosoft.com/wp-content/uploads/2024/08/logo-3.svg" style={{ width: "8rem" }} alt="Footer Logo" />
                </div>
            </footer>
        </div>
    );
};

export default Declaration;
