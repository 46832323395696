import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const AddFollowupModal = ({id}) => {
    const [formData, setFormData] = useState({
        followUp: '',
        description: '',
        assignedTo: ''
    });

    const [assignedUsers, setAssignedUsers] = useState([])
    const [userId, setUserId] = useState("");

    const getAssignedUsers = async () =>{
        const response = await fetch(`/api/get_assign_to`);
        const data1 = await response.json();
        console.log(data1);
        if(data1.suc){
            setAssignedUsers(data1.data)
        }
    }

    const verifyUser = async () => {
        try {
            const response = await fetch(`/auth/get_user_data`, {
                headers: {
                    "Authorization": localStorage.getItem("token")
                }
            });
            const data = await response.json();

            if (data.suc) {
                setUserId(data.auth.id);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }
    
    useEffect(() => {
        getAssignedUsers();
        verifyUser()
    }, [])

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(formData.assignedTo === ""){
            toast.error("assigned_to can't be empty");
            return;
        }
        if(formData.followUp === ""){
            toast.error("followUp can't be empty");
            return;
        }
        if(formData.description ===""){
            toast.error("description can't be empty");
            return;
        }

        const newFormData = new FormData();
        newFormData.append('task_title', formData.followUp);
        newFormData.append('description', formData.description);
        newFormData.append('assigned_to', formData.assignedTo);
        newFormData.append('created_by', userId);
        newFormData.append('type', 'followup');
        newFormData.append('reminder', '0');
        newFormData.append('follow_up', id);
        newFormData.append('lead_id', id);
        
        console.log("Form Data Submitted:", [...newFormData]);

        const response = await fetch('/api/follow_up_create',{
            method: "POST",
            body: newFormData
        })
        const data = await response.json();
        console.log(data);

        if(data.suc){
            toast.success(data.msg);
        }
        else{
            toast.warn(data.msg);
        }
    };

    return (
        <div className="modal-body">
            <form onSubmit={handleSubmit}>
                {/* Follow Up Input */}
                <div className="mb-3">
                    <label htmlFor="followUp" className="form-label"> Follow Up Title </label>
                    <input
                        type="text"
                        className="form-control"
                        id="followUp"
                        name="followUp"
                        value={formData.followUp}
                        onChange={handleChange}
                        required
                        placeholder="Enter follow up titile"
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="description" className="form-label"> Follow Up Description </label>
                    <input
                        type="text"
                        className="form-control"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        required
                        placeholder="Enter follow up descrition"
                    />
                </div>

                {/* Assigned To Input */}
                <div className="mb-3">
                    <label htmlFor="assignedTo" className="form-label">Assigned To</label>
                    <select
                        className="form-control"
                        id="assignedTo"
                        name="assignedTo"
                        value={formData.assignedTo}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Assign to</option>
                        {assignedUsers.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user.full_name}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Submit Button */}
                <div className="modal-footer p-0">
                    <button type="button" className="btn btn-secondary mb-0" data-bs-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-primary mb-0">Add Follow Up</button>
                </div>
            </form>
        </div>
    );
};

export default AddFollowupModal;
