import React, { useEffect, useState } from 'react';


const RepeaterField = ({ length, index, handleIt, onHandleItChange, setHandleIt, showSelectedColumns }) => {
    
    const [fields, setFields] = useState([{ field: "", operator: "", value: "" }]);

    const [isAllSelected, setIsAllSelected] = useState(true);
    const [isORSelected, setIsORSelected] = useState(true);

    useEffect(() => {
        getCurrentFilter();
    }, [])

    useEffect(() => {
        if (handleIt) {
            onHandleItChange(fields, true, index, length, isAllSelected, isORSelected);
            setHandleIt(false);
        }
        // eslint-disable-next-line
    }, [handleIt]);

    const getCurrentFilter = async () => {
        const response = await fetch(`/api/get_current_filter`)
        const data = await response.json();
        console.log("data............", data);
        if (data.suc) {
            console.log("inside filter", data.filter[0].filters);
            setFields(data.filter[index].filters);

            if (data.filter[index].group_criteria === "all") {
                setIsAllSelected(true);
            }
            else {
                setIsAllSelected(false);
            }

            const key = `main_operator_${index}`;
            if (data.filter[key] === "or") {
                setIsORSelected(true);
            }
            else {
                setIsORSelected(false);
            }
        }
    }



    const handleAddField = (e) => {
        e.preventDefault();
        // if (fields.length >= 4) {
        //     return;
        // }
        setFields([...fields, { field: "", operator: "", value: "" }]);
        // console.log(fields)
    };

    const handleRemoveField = (index, e) => {
        e.preventDefault();
        if (fields.length === 1) {
            return;
        }
        const newFields = [...fields];
        newFields.splice(index, 1);
        setFields(newFields);
        console.log(index, newFields)
    };

    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const newFields = [...fields];
        newFields[index][name] = value;
        setFields(newFields);
        console.log(fields)
    };

    // const handleChange = (index, e, dateField) => {
    //     const { name, value } = e.target;
    //     const newFields = [...fields];
    
    //     // If BETWEEN operator is selected
    //     if (newFields[index].operator === 'BETWEEN') {
    //         // Update startDate or endDate accordingly
    //         if (dateField === 'startDate') {
    //             newFields[index].startDate = value;
    //         } else if (dateField === 'endDate') {
    //             newFields[index].endDate = value;
    //         }
    
    //         // Once both dates are selected, update the value field
    //         if (newFields[index].startDate && newFields[index].endDate) {
    //             newFields[index].value = `${newFields[index].startDate},${newFields[index].endDate}`;
    //         }
    //     } else {
    //         // For other operators, just update the field as usual
    //         newFields[index][name] = value;
    //     }
    
    //     setFields(newFields);
    //     console.log(fields);
    // };

    // const handleChange = (index, e, dateField) => {
    //     const { name, value } = e.target;
    //     const newFields = [...fields];
    
    //     // Handle the BETWEEN operator
    //     if (newFields[index].operator === 'BETWEEN') {
    //         if (dateField === 'startDate') {
    //             newFields[index].startDate = value;
    //         } else if (dateField === 'endDate') {
    //             newFields[index].endDate = value;
    //         }
    
    //         // Format the dates to dd-mm-yyyy and update the value field
    //         const formatDate = (date) => {
    //             const [year, month, day] = date.split('-');
    //             return `${day}-${month}-${year}`;
    //         };
    
    //         if (newFields[index].startDate && newFields[index].endDate) {
    //             const formattedStart = formatDate(newFields[index].startDate);
    //             const formattedEnd = formatDate(newFields[index].endDate);
    //             newFields[index].value = `${formattedStart},${formattedEnd}`;
    //         } else {
    //             // If one of the dates is missing, show the partial value
    //             const formattedStart = newFields[index].startDate
    //                 ? formatDate(newFields[index].startDate)
    //                 : 'dd-mm-yyyy';
    //             const formattedEnd = newFields[index].endDate
    //                 ? formatDate(newFields[index].endDate)
    //                 : 'dd-mm-yyyy';
    //             newFields[index].value = `${formattedStart},${formattedEnd}`;
    //         }
    //     } else {
    //         // For other operators, update the field normally
    //         newFields[index][name] = value;
    //     }
    
    //     setFields(newFields);
    //     console.log(fields);
    // };
    


    const handleToggle = () => {
        setIsAllSelected(prevState => !prevState);
    };

    const handleToggleMain = () => {
        setIsORSelected(prevState => !prevState);
    };

    // const [fieldText, setFieldText] = useState("");
    // const [operator, setOperator] = useState("");
    // const [values, setValues] = useState("");

    // const getColumnValue = (row, column) => {
    //     if (column === "number") {
    //         column = "phone";
    //     }
    //     const key = column;
    //     return row[key] || 'N/A';
    // };

    const myOperators = [
        '=',
        '!=',
        '<=',
        '>=',
        'LIKE',
        // 'BETWEEN',
        'IN',
    ];



    return (
        <div className="repeater-container">
            {index != 0 && (
                <div className="middle-content text-center" style={{ backgroundColor: "white" }}>
                    <span className="toggle-container pt-4 pb-4">
                        <div
                            className={`toggle-button ${isORSelected ? 'active' : ''}`}
                            onClick={handleToggleMain}
                        >
                            <span className={`toggle-label ${isORSelected ? 'selected2' : ''}`}>OR</span>
                            <span className={`toggle-label ${!isORSelected ? 'selected2' : ''}`}>AND</span>
                        </div>
                    </span>
                </div>
            )}

            <div className="content-heading">
                <span className="toggle-container mb-4">
                    <div
                        className={`toggle-button ${isAllSelected ? 'active' : ''}`}
                        onClick={handleToggle}
                    >
                        <span className={`toggle-label ${isAllSelected ? 'selected' : ''}`}>All</span>
                        <span className={`toggle-label ${!isAllSelected ? 'selected' : ''}`}>Any</span>
                    </div>
                </span>
                <span>{`${isAllSelected ? "Meet All Criteria" : "Meet Any Criteria"}`}</span>


            </div>


            {fields.map((fieldElements, index) => (
                <React.Fragment key={index}>
                    {/* {index !== 0 && <hr />} */}
                    <div className="repeater-field row align-items-center mb-3 me-0 justify-content-end">
                        <div className="col-sm-3 mb-sm-0 mb-3 filter-select">
                            <select name='field' value={fieldElements.field} className="form-select" onChange={(e) => handleChange(index, e)} aria-label="Default select example">
                                <option value="">Select Field</option>
                                {showSelectedColumns.map((col) => (
                                    <option key={col} value={col === "number" ? "phone" : col}>{col}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-sm-3 mb-sm-0 mb-3">
                            <select name='operator' value={fieldElements.operator} className="form-select" onChange={(e) => handleChange(index, e)} aria-label="Default select example">
                                <option value="">Select Operator</option>
                                {myOperators.map((col) => (
                                    <option key={col} value={col === "number" ? "phone" : col}>{col}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-sm-4 mb-sm-0 mb-3">
                            {fieldElements.operator === "=" ||
                                fieldElements.operator === "!=" ||
                                fieldElements.operator === "LIKE" ? (
                                <input
                                    type="text"
                                    placeholder="Value"
                                    name="value"
                                    value={fieldElements.value}
                                    onChange={(e) => handleChange(index, e)}
                                    className="form-control"
                                />
                            ) : fieldElements.operator === "<=" || fieldElements.operator === ">=" ? (
                                <input
                                    type="number"
                                    placeholder="Value"
                                    name="value"
                                    value={fieldElements.value}
                                    onChange={(e) => handleChange(index, e)}
                                    className="form-control"
                                />
                            ) : fieldElements.operator === "BETWEEN" ? (
                                <div className="d-flex">
                                    <input
                                        type="date"
                                        placeholder="Start Date"
                                        name="startDate"
                                        value={fieldElements.startDate}
                                        onChange={(e) => handleChange(index, e, 'startDate')}
                                        className="form-control me-2"
                                    />
                                    <input
                                        type="date"
                                        placeholder="End Date"
                                        name="endDate"
                                        value={fieldElements.endDate}
                                        onChange={(e) => handleChange(index, e, 'endDate')}
                                        className="form-control"
                                    />
                                </div>
                            ) : (
                                <input
                                    type="text"
                                    placeholder="Value"
                                    name="value"
                                    value={fieldElements.value}
                                    onChange={(e) => handleChange(index, e)}
                                    className="form-control"
                                />
                            )}
                        </div>
                        <div className="col-sm-2 col-4 p-0 repeater-buttons">
                            <div className="row justify-content-end me-0">
                                {index !== 0 && (
                                    <div className="col-6 p-0 text-end">
                                        <button onClick={(e) => handleRemoveField(index, e)} className="my-btn3">
                                            <i className="fa-solid fa-circle-minus" style={{ color: "red" }}></i>
                                        </button>
                                    </div>
                                )}
                                {/* {fields.length < 4 && ( */}
                                <div className="col-6 form-group p-sm-0 text-end text-sm-start">
                                    <button className="my-btn3 add-button" onClick={handleAddField}>
                                        <i className="fa-solid fa-circle-plus" style={{ color: "royalblue" }}></i>
                                    </button>
                                </div>
                                {/* )} */}
                            </div>
                        </div>
                    </div>


                </React.Fragment>
            ))}
        </div>
    );
};

export default RepeaterField;

