import './App.css';
import './style/style.css'
import './style/responsiveStyle.css'
import Dashboard from './components/Dashboard';
import { Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import RoleManager from './components/RoleManager';
import ForgotPassword from './components/ForgotPassword';
import AddUser from './components/AddUser';
import Form from './Form/Form';
import EducationalDetails from './Form/EducationalDetails';
import Documents from './Form/Documents';
import Declaration from './Form/Declaration';
import Payment from './Form/Payment';
import StudentDetails from './components/StudentDetails';
import Profile from './components/Profile';

function App() {
  return (
    <div>
      <Routes>
        <Route path='' element={<Dashboard/>}/>
        <Route path='role' element={<RoleManager/>} />
        <Route path='login' element={<Login/>} />
        <Route path='forgot_password' element={<ForgotPassword/>} />
        <Route path='add_user' element={<AddUser/>}/>
        <Route path='form' element={<Form/>}/>
        <Route path='form/educational_details' element={<EducationalDetails/>}/>
        <Route path='form/documents' element={<Documents/>}/>
        <Route path='form/declaration' element={<Declaration/>}/>
        <Route path='form/payment' element={<Payment/>}/>
        <Route path='student_details/:id' element={<StudentDetails/>}/>
        <Route path='profile' element={<Profile/>}/>
      </Routes>
    </div>
  );
}

export default App;